import CloseRegular from "@ui-library/icons/CloseRegular";
import React from "react";
import { BannerButton, BannerImg, BannerWrapper, BannerLogoContainer } from "./style";
import Icon from "@ui-library/core/Icon";

interface OpportunityStepper {
  onClose(): void;
}

export const HeaderOpportunity = ({ onClose }: OpportunityStepper) => {
  return (
    <BannerWrapper>
      <BannerLogoContainer>
        <BannerImg
          src="/Content/App/dist/img/logo-sl-bucom-white.svg"
          alt="logo"
        />
      </BannerLogoContainer>

      <BannerButton onClick={() => onClose()} data-testid="close-modal-button">
        <Icon icon={CloseRegular} size="2rem" />
      </BannerButton>
    </BannerWrapper>
  );
};
