import thor from "@sl/thor"
import { defaultGa4MetasParams } from "../../view"
import { PreneurModel } from "src/types/container/preneur"
import { mapEstateType } from "../../utils/classifieds/product"
import { CodeTypeBien } from "src/types/classifieds"
import { cryptEmail, mapToDistributionTypeGlobal } from "../../tracking-tool"
import { mapLocation } from "../../utils/classifieds/search"
import { ElementZone } from "../../types"
import { TrackingZoneEnum } from "../../types/enums"

export function sendEventSeekerOpportunity(
  av_zone: ElementZone = TrackingZoneEnum.HEADER
) {
  thor.pushEvent("entrust_your_research_to_the_experts", {
    av_category: "classified",
    av_zone,
  })
}

export function sendEventSeekerOpportunityStep1() {
  thor.pushEvent("page_view", {
    ...defaultGa4MetasParams,
    av_pagetitle: "entrust_the_research_to_experts_landing",
    av_category: "classified",
  })
}

export function sendEventSeekerOpportunityStep2() {
  thor.pushEvent("page_view", {
    ...defaultGa4MetasParams,
    av_pagetitle: "entrust_the_research_to_experts_project",
    av_category: "classified",
  })
}

export function sendEventSeekerOpportunityStep3(data: PreneurModel) {
  thor.pushEvent("page_view", {
    ...defaultGa4MetasParams,
    av_pagetitle: "entrust_the_research_to_experts_business_type",
    av_category: "classified",
    av_estate_type_global: mapEstateType(
      data.codeTypeBien as unknown as CodeTypeBien
    ),
    av_distribution_type_global: mapToDistributionTypeGlobal(
      data?.idTypeTransaction
    )?.toString(),
    av_distribution_subtype: data?.idTypeTransaction?.toString(),
    av_search: {
      location: mapLocation(data?.localite ?? []),
    },
  })
}

export function sendEventSeekerOpportunityStep4(data: PreneurModel) {
  thor.pushEvent("page_view", {
    ...defaultGa4MetasParams,
    av_pagetitle: "entrust_the_research_to_experts_business_description",
    av_category: "classified",
    av_search: {
      business_type: data?.secteurActivite?.toString(),
    },
  })
}

export function sendEventSeekerOpportunityStep5() {
  thor.pushEvent("page_view", {
    ...defaultGa4MetasParams,
    av_pagetitle: "entrust_the_research_to_experts_contact",
    av_category: "classified",
  })
}

export function sendEventSeekerOpportunityStep6(data: PreneurModel) {
  thor.pushEvent("page_view", {
    ...defaultGa4MetasParams,
    av_pagetitle: "entrust_the_research_to_experts_confirmation",
    av_category: "classified",
    av_estate_type_global: mapEstateType(
      data.codeTypeBien as unknown as CodeTypeBien
    ),
    av_distribution_type_global: mapToDistributionTypeGlobal(
      data?.idTypeTransaction
    )?.toString(),
    av_distribution_subtype: data?.idTypeTransaction?.toString(),
    av_search: {
      location: mapLocation(data?.localite ?? []),
      business_type: data?.secteurActivite?.toString(),
    },
    email_account: cryptEmail(data?.email ?? ""),
  })
}
