import {EstateTypeGA4} from '@sl/react-thor'
import {TrackingBusinessSubTypeGA4, TrackingBusinessTypeGA4} from "../../../../../types/business-type";
import {
    CodeTypeBien,
    Equipment,
    IdTypeTransaction,
    LeaseType,
    ListingClassifiedDocument,
    SubCoworkingType
} from "../../../../../types/classifieds";
import {TrackingItemListName, TrackingTypeBienEnum} from "../../types/enums";
import {TrackingExtendedItem} from "../../types";
import { mapDistributionSubType, mapToDistributionTypeGlobal } from '../../tracking-tool';

export const mapItemsGA4 = (
    classifieds: ListingClassifiedDocument[],
    list_name: TrackingItemListName
): TrackingExtendedItem[] => {
    return classifieds.map((item: ListingClassifiedDocument, itemKey: number) =>
        mapItem(item, itemKey, list_name)
    )
}

export const mapDetailItemsGA4 = (
    detail: ListingClassifiedDocument | undefined,
    suggestions?: ListingClassifiedDocument[]
): TrackingExtendedItem[] => {
    const items: TrackingExtendedItem[] = []

    if (detail) {
        items.push({
            ...mapItem(detail),
            list_name: TrackingItemListName.CLASSIFIED_DETAIL
        })
    }

    if (suggestions) {
        items.push(...suggestions.map(s => ({
            ...(mapItem(s)),
            list_name: TrackingItemListName.CLASSIFIED_DETAIL_SIMILARS_BOTTOM
        })))
    }

    return items.map((item, i) => ({ ...item, index: i }))
}

export function mapItem(
    classified: ListingClassifiedDocument,
    position?: number,
    list_name?: TrackingItemListName
): TrackingExtendedItem {
    const {
        idAnnonce,
        idTypeTransaction,
        codeTypeBien,
        equipments,
        mandat: { mandataires },
        localisation: { codeDepartement, nomVille, idIris },
        space,
    } = classified

    const mainAgency = mandataires.find(m => m.isPrincipal)

    return {
        list_name,
        id: idAnnonce.toString(),
        index: position,
        price: mapPrice(classified),
        estate_type: mapEstateType(classified?.codeTypeBien),
        distribution_type: idTypeTransaction
        ? mapToDistributionTypeGlobal(idTypeTransaction as any)?.toString()
        : '',
        publication_id: 'SLBC',
        name: 'classified',
        indoor_surface: codeTypeBien !== CodeTypeBien.Terrain ? space?.toString() : undefined,
        subtype: mapSubEstateType(classified)?.toString(),
        currency: 'EUR',
        promotion_type: mapProductStatus(classified),
        business_type: mapBusinessType(classified),
        client_id: mainAgency?.idRcu,
        client_type: 'pro',
        outdoor_terrace_exists: equipments?.includes(Equipment.Terrasse) ? 'true' : 'false',
        geolocation: idIris?.toString(),
        distribution_subtype: mapDistributionSubType(classified) ?? '',

        land_surface: codeTypeBien === CodeTypeBien.Terrain ? space?.toString() : undefined,
        country: 'france',
        province: codeDepartement,
        city: nomVille,
        parking: equipments?.includes(Equipment.Parking) ? 'true' : 'false',
        media: 'photo',
    }
}

export function mapEstateType(codeTypeBien: CodeTypeBien): EstateTypeGA4 {
    switch (codeTypeBien) {
        case CodeTypeBien.Terrain:
            return 'av_9'
        case CodeTypeBien.LocalActiviteEntrepot:
            return 'av_12'
        case CodeTypeBien.Boutique:
        case CodeTypeBien.LocalCommercial:
            return 'av_13'
        case CodeTypeBien.Coworking:
        case CodeTypeBien.Bureaux:
            return 'av_7'
        default:
            return 'av_13'
    }
}

export function mapSubEstateType({
                                     idTypeTransaction,
                                     codeTypeBien,
                                     subCoworking,
                                     office,
                                 }: ListingClassifiedDocument): TrackingTypeBienEnum | undefined {
    if (idTypeTransaction === IdTypeTransaction.FondDeCommerce) {
        return TrackingTypeBienEnum.BUSINESS_SOLD
    }

    switch (codeTypeBien) {
        case CodeTypeBien.Terrain:
            return TrackingTypeBienEnum.PLOT
        case CodeTypeBien.Boutique:
            return TrackingTypeBienEnum.SHOP
        case CodeTypeBien.LocalActiviteEntrepot:
            return TrackingTypeBienEnum.STORAGE
        case CodeTypeBien.LocalCommercial:
            return TrackingTypeBienEnum.COMMERCIAL_SPACE
        case CodeTypeBien.Bureaux:
            switch (office?.leaseType) {
                case LeaseType.Classic369LeaseOffice:
                    return TrackingTypeBienEnum.OFFICE_COMMERCIAL_LEASE
                case LeaseType.ShortLeaseOffice:
                    return TrackingTypeBienEnum.OFFICE_SHORT_LEASE
                case LeaseType.ServicedOffice:
                    return TrackingTypeBienEnum.OFFICE_SERVICED
                default:
                    return TrackingTypeBienEnum.OFFICE
            }
        case CodeTypeBien.Coworking:
            return TrackingTypeBienEnum.COWORKING_SPACE

        case CodeTypeBien.SubCoworking:
            switch (subCoworking?.type) {
                case SubCoworkingType.Workstation:
                    return TrackingTypeBienEnum.COWORKING_WORKSTATION
                case SubCoworkingType.PrivateWorkstation:
                    return TrackingTypeBienEnum.COWORKING_PRIVATE_WORKSTATION
                case SubCoworkingType.PrivateOffice:
                    return TrackingTypeBienEnum.COWORKING_PRIVATE_OFFICE
                default:
                    return TrackingTypeBienEnum.COWORKING_SPACE
            }
        default:
            return undefined
    }
}

function mapPrice({ idTypeTransaction, price }: ListingClassifiedDocument): number | undefined {
    if (!price) {
        return undefined
    }
    const { buy, rent } = price

    switch (idTypeTransaction) {
        case IdTypeTransaction.Achat:
        case IdTypeTransaction.FondDeCommerce:
            return buy?.price.value
        case IdTypeTransaction.Location:
            return rent?.monthlyPrice?.value ?? rent?.monthlyPriceByDesk?.value
        default:
            return undefined
    }
}

function mapProductStatus(classified: ListingClassifiedDocument): string | undefined {
    const products: string[] = []

    if (classified.isBoostHighlight) {
        products.push('boost_extend')
    } else if (classified.isAnnonceOr) {
        products.push('gold')
    }

    if (classified.mandat.isExclusive) {
        products.push('exclusivity')
    }

    return products.join('|')
}

function mapBusinessType(classified: ListingClassifiedDocument): string | undefined {
    if (
        classified.idTypeTransaction !== IdTypeTransaction.FondDeCommerce ||
        !classified.businessSold
    ) {
        return undefined
    }

    const { businessType, subBusinessType } = classified.businessSold

    return subBusinessType
        ? TrackingBusinessSubTypeGA4[subBusinessType]
        : TrackingBusinessTypeGA4[businessType]
}
