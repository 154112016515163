export const TrackingEstateTypeEnums = {
    Terrain: 4,
    Boutique: 6,
    Locaux: 7.1,
    Entrepot: 7,
    Bureaux: 8,
    Coworking: 17,
    CoworkingEspace: 18,
    CoworkingToutPoste: 18.1,
    CoworkingPosteNomade: 18.2,
    CoworkingBureauFerme: 18.3,
    CoworkingSalleDeReunion: 18.4,
    CoworkingPostePrivatif: 18.5,
    VenteFondCommerce: 31
} as const

export const TrackingTypeBienEnums = {
    Bureaux: 800,
    Boutique: 600,
    Local: 700,
    Entrepot: 750,
    Terrain: 400,
    VenteFondCommerce: 900
} as const

export type TrackingTypeBienEnum = typeof TrackingTypeBienEnums[keyof typeof TrackingTypeBienEnums]

export type TrackingEstateTypeEnum = typeof TrackingEstateTypeEnums[keyof typeof TrackingEstateTypeEnums];
