import { Dispatch } from "react";
import { trackOppPreneurFormErrorInteraction, trackOppPreneurSubmitInteraction } from "src/js/tracking/ga3/tracking-wrapper";
import { AutocompleteLocalisationType } from "src/react/common/locality-autocomplete/types";
import isEmailFormatValid from "src/react/common/validation/email-validation";
import isPhoneNumberFormatValid from "src/react/common/validation/phone-number-validation";
import { writeOppPreneur } from "src/react/services/storage-service";
import { TypeTransactionEnum } from "src/react/tools/constant";
import { PreneurModel } from "src/types/container/preneur";
import {
  SET_FORM_DATA,
  SET_FORM_RESULT,
  SET_FORM_STEP,
} from "../context/actions";
import { sendOppPreneurService } from "../services";
import { initialState } from "./reducer";

const ErrorMessageDefault = "Merci de compléter ce champ";
const ErrorMessageSurface = "Merci de saisir une surface en m2";

const ErrorMessageDate =
  "Veuillez renseigner une date valide et postérieure à la date du jour (Exemple : 21/12/2023).";
const ErrorMessageEmail = "Merci de saisir une adresse email valide";
const ErrorMessageTel =
  "Merci de saisir un numéro de téléphone valide (fixe ou portable)";

export type formattedDateProps = {
  inputDate: Date;
  format: "limit" | "fr" | "en";
};

const isDateBeforeToday = (date: Date) => {
  return date < new Date(new Date().toDateString());
};

type DispatchProps = {
  type: string;
  [index: string]: any;
};

export const handleSubmitFirstStep = (
  dispatch: Dispatch<DispatchProps>,
  state: PreneurModel,
  step: number
) => {
  const newState = {
    ...state,
    errors:
      state.idTypeTransaction === TypeTransactionEnum.VenteFondCommerce
        ? {
            idTypeTransaction: !!state.idTypeTransaction
              ? null
              : ErrorMessageDefault,
            localite:
              !!state.localite && state?.localite?.length > 0
                ? null
                : ErrorMessageDefault,
          }
        : {
            idTypeTransaction: !!state.idTypeTransaction
              ? null
              : ErrorMessageDefault,
            codeTypeBien: !!state.codeTypeBien ? null : ErrorMessageDefault,
            surface:
              !!state.surface && parseInt(state.surface) > 0
                ? null
                : ErrorMessageSurface,
            localite:
              !!state.localite && state?.localite?.length > 0
                ? null
                : ErrorMessageDefault,
          },
  };

  if (Object.keys(newState.errors).find((key) => !!newState.errors[key])) {
    const errorString = Object.keys(newState.errors).find(
      (key) => !!newState.errors[key]
    );
    trackOppPreneurFormErrorInteraction(errorString);
    dispatch({ type: SET_FORM_DATA, formData: newState });
  } else dispatch({ type: SET_FORM_STEP, step });
};

export const handleSubmitSecondStep = (
  dispatch: Dispatch<DispatchProps>,
  state: PreneurModel,
  step: number
) => {
  const newState = {
    ...state,
    errors:
      state.idTypeTransaction === TypeTransactionEnum.VenteFondCommerce
        ? {
            secteurActivite: !!state.secteurActivite
              ? null
              : ErrorMessageDefault,
          }
        : {
            activite: !!state.activite ? null : ErrorMessageDefault,
            effectif: !!state.effectif ? null : ErrorMessageDefault,
          },
  };

  if (Object.keys(newState.errors).find((key) => !!newState.errors[key])) {
    const errorString = Object.keys(newState.errors).find(
      (key) => !!newState.errors[key]
    );
    trackOppPreneurFormErrorInteraction(errorString);
    dispatch({ type: SET_FORM_DATA, formData: newState });
  } else dispatch({ type: SET_FORM_STEP, step });
};

export const handleSubmitThirdStep = (
  dispatch: Dispatch<DispatchProps>,
  state: PreneurModel,
  step: number
) => {
  const newState = {
    ...state,
    errors: {
      dateInstallation:
        !!state.dateInstallation && !isDateBeforeToday(state.dateInstallation)
          ? null
          : ErrorMessageDate,
    },
  };
  if (Object.keys(newState.errors).find((key) => !!newState.errors[key])) {
    const errorString = Object.keys(newState.errors).find(
      (key) => !!newState.errors[key]
    );
    trackOppPreneurFormErrorInteraction(errorString);
    dispatch({ type: SET_FORM_DATA, formData: newState });
  } else dispatch({ type: SET_FORM_STEP, step });
};

export const handleSubmitFourthStep = async (
  dispatch: Dispatch<DispatchProps>,
  state: PreneurModel,
  step: number
) => {
  const newState = {
    ...state,
    errors: {
      societe: !!state.societe ? null : ErrorMessageDefault,
      civilite: !!state.civilite ? null : ErrorMessageDefault,
      nom: !!state.nom ? null : ErrorMessageDefault,
      email:
        state?.email && !isEmailFormatValid(state.email)
          ? null
          : ErrorMessageEmail,
      telephone:
        state?.telephone && !isPhoneNumberFormatValid(state.telephone)
          ? null
          : ErrorMessageTel,
      getSpecialist: state.getSpecialist ? null : ErrorMessageDefault,
    },
  };

  if (Object.keys(newState.errors).find((key) => !!newState.errors[key])) {
    const errorString = Object.keys(newState.errors).find(
      (key) => !!newState.errors[key]
    );
    trackOppPreneurFormErrorInteraction(errorString);
    dispatch({ type: SET_FORM_DATA, formData: newState });
  } else {
    const formattedData: any = { ...state };

    formattedData.codeInsees = state?.localite
      ?.filter(item =>item.localisationType === AutocompleteLocalisationType.Ville)
      .map(el => el.value) ?? []

    formattedData.codeDepartements = state?.localite
      ?.filter(item => item.localisationType === AutocompleteLocalisationType.Departement)
      .map(el => el.value) ?? []

    formattedData.dateInstallation = state?.dateInstallation?.toISOString();

    if (state.idTypeTransaction === TypeTransactionEnum.VenteFondCommerce) {
      formattedData.surface = 0;
      formattedData.codeTypeBien = 600;
      formattedData.effectif = null;
    } else {
      formattedData.surface = state?.surface ? parseInt(state?.surface) : 1;
      formattedData.secteurActivite = 0;
    }
    await trackOppPreneurSubmitInteraction(state.email);
    try {
      await sendOppPreneurService(formattedData);
      await dispatch({ type: SET_FORM_STEP, step });
      writeOppPreneur(initialState.formData);
    } catch {
      await trackOppPreneurFormErrorInteraction(state.email);
      dispatch({ type: SET_FORM_RESULT, isFailed: true });
      dispatch({ type: SET_FORM_STEP, step });
    }
  }
};
