import styled from "styled-components";

export const BannerImg = styled.img`
  width: 16.2rem;
  height: 3.5rem;
`;

export const BannerWrapper = styled.div`
  background-color: #166886;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: 'btn logo .';
  align-items: center;
  justify-content: center;
  justify-items: start;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 1.6rem 2.4rem;

  @media screen and (min-width: 1024px) {
    grid-template-areas: '. logo btn';
    justify-items: end;
    padding: 2.4rem 6.4rem;
  }
`

export const BannerLogoContainer = styled.a`
  grid-area: logo;
  height: 3.6rem;
  width: 16.2rem;
  color: #FFFFFF;
`

export const BannerButton = styled.div`
  grid-area: btn;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
`
