function scrollFct() {
    'use strict';

    // keys we want to prevent from window scroll
    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    let keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    let preventDefault = function (evt) {
        evt = evt || window.event;
        if (evt.preventDefault) {
            evt.preventDefault();
        }
        evt.returnValue = false;
    };

    let preventDefaultForScrollKeys = function (evt) {
        if (keys[evt.keyCode]) {
            preventDefault(evt);
            return false;
        }
    };

    return {
        disableScroll: function () {
             if (window.addEventListener) {
                 // older FF
                 window.addEventListener('DOMMouseScroll', preventDefault, false);
             }
             window.onwheel = preventDefault; // modern standard
             window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
             window.ontouchmove = preventDefault; // mobile
             document.onkeydown = preventDefaultForScrollKeys;
        },
        enableScroll: function () {
             if (window.removeEventListener) {
                 window.removeEventListener('DOMMouseScroll', preventDefault, false);
             }
             window.onmousewheel = document.onmousewheel = null;
             window.onwheel = null;
             window.ontouchmove = null;
             document.onkeydown = null;
        }
    }
}

let scroll = scrollFct();

export {scroll};
