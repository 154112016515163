export const infos = {
  links: [
    {
      id: '1',
      value: 'Politique Générale de Protection des Données',
      href: 'https://www.seloger.com/CGU_politique_de_confidentialite.html',
    },
    { id: '2', value: "Conditions Générales d'Utilisation", href: '/conditions-generales' },
    { id: '3', value: 'Paramétrer mes cookies', href: '' },
    { id: '4', value: 'Fonctionnement du site', href: '' },
  ],
  subtitle: 'Infos pratiques',
}

export const servicesPro = {
  links: [
    { id: '1', value: 'Diffusez vos annonces', href: '/diffusez-vos-annonces' },
    { id: '2', value: 'Nous contacter', href: '' },
  ],
  subtitle: 'Services Pro',
}

export const sites = {
  links: [
    {
      id: '1',
      value: 'SeLoger.com - Petites annonces immobilières',
      href: 'https://www.seloger.com/',
    },
    {
      id: '1',
      value: 'SeLoger.com - Dépôt d’annonce entre particuliers',
      href: 'https://www.seloger.com/depot-annonce/',
    },
    {
      id: '2',
      value: 'BellesDemeures.com - Immobilier de prestige',
      href: 'https://www.bellesdemeures.com/',
    },
    {
      id: '3',
      value: "SeLoger.com - Prix de l'immobilier au m²",
      href: 'https://www.seloger.com/prix-de-l-immo/vente/pays/france.htm#?CMP=INT_AGB_Footer',
    },
    { id: '4', value: 'SeLogerNeuf.com - Immobilier neuf', href: 'https://www.selogerneuf.com/' },
  ],
  subtitle: "SeLoger bureaux & commerces, c'est aussi...",
}

export const linksSeo = {
  links: [
    { id: '1', value: 'Location Bureau', href: '/location/bureau' },
    { id: '2', value: 'Location Local commercial / Boutique', href: '/location/boutique' },
    {
      id: '3',
      value: "Location Local d'activités / Entrepôt",
      href: '/location/local-d-activites-entrepot',
    },
    { id: '4', value: 'Vente Fonds de Commerce', href: '/achat/fonds-de-commerce' },
    { id: '5', value: 'Location local professionnel', href: '/location/local-professionnel' },
    { id: '6', value: 'Vente local professionnel', href: '/achat/local-professionnel' },
  ],
}
