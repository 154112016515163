 /*
* TOC
*
* FOOTER
*/
export default {
    //Show or Hide the links in the SEO Footer only on Mobile 
    toggleLinksSeoFooter: function () {
        let seoFooterTitles = document.getElementsByClassName("js-footer-seo-collapsable");
        if (seoFooterTitles) {
            for (let i = 0; i < seoFooterTitles.length; i++) {
                seoFooterTitles[i].addEventListener("click", function(){
                    if (this.classList.contains("open")) {
                        this.classList.remove("open");
                    } 
                    else {
                        let formerOpenList = document.querySelector(".js-footer-seo-collapsable.open");
                        if(formerOpenList){
                            formerOpenList.classList.remove("open");
                        }
                        this.classList.toggle("open");
                    }           
                });
            }
        }
    },
};
