import { AutocompleteLocalityType } from "src/react/common/locality-autocomplete/types"

export interface ListingAgencyDocument {
  idAgence: number
  idTiers: number
  idRcu: string
  codePostal: string
}

export interface SearchAgencyResponse {
  autocompleteLocalites: AutocompleteLocalityType[]
  results: ListingAgencyDocument[]
  pagination: {
    count: number
    currentPage: number
    resultPerPage: number
    maxPage: number
  }
  criterias: {
    sort: SearchAgencySort
  }
}

export enum SearchAgencySort {
  Standard = 0,
  NameDesc = 1,
  NameAsc = 2,
  LocaliteDesc = 3,
  LocaliteAsc = 4
}

export interface AgencyDetailResponse {
  listingAgency: ListingAgencyDocument
}
