import thor from "@sl/thor";
import { initPageTracking } from './tracking/ga3/tracking-wrapper';
import { loadScript } from './utils/utils';
import Ads from './ads';
import { createConsentService } from '@aviv-csnt/core'

(function () {
  let isConsentGranted = false

  createConsentService("gpgLib6b0x9esY").then(() => {
      isConsentGranted = window?.__AVIV_CSNT__?.getIsConsentGranted() ?? false
      initPageTracking('GTM-PVVJVR5'); // GA3
      initPageTracking('GTM-5THNWXP'); // GA4

      if (isConsentGranted) {
        thor.onConsent();
        loadScript("https://pagead2.googlesyndication.com/tag/js/gpt.js", () => { 
          Ads.initAds();
        });
      }
  })

  // Interdiction de taper des caractères alphanumerics dans les champs de type tel
  // On accepte également le caractère + (numéro de tel internationaux)
  document.addEventListener("keypress", function (event) {
    if (event.target.tagName != null && event.target.type != null &&
      event.target.tagName.toLowerCase() === "input" && event.target.type.toLowerCase() === "tel") {

      if ((event.charCode < 48 || event.charCode > 57) && event.charCode !== 43) {
        event.preventDefault();
      }
    }
  });
})();
