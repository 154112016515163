import { theme } from '@ui-library/slbc';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import InfoPratique from './components/info-pratique';
import LienSeo from './components/lien-seo';
import SearchReference from './components/search-reference';
import Socials from './components/socials';
import { infos, linksSeo, servicesPro, sites } from './services/constants';

const Span = styled.div`
  background-color: #2B2B2B;
  color: white;
  width: 100%;
  margin: 0 auto;
  font-size: 1.6rem;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
  &::before {
    display: table;
    content: ' ';
  }
  @media (min-width: 768px) {
    width: 100%;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 1312px;
  margin: 0 auto;
  padding: 0 1.6rem;
`

const SearchAndInfo = styled.div`
  @media screen and (min-width: 992px) {
    flex-direction: row;
    padding: 5.6rem 0 3.2rem;
  }
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Container = styled.div`
  @media screen and (min-width: 992px) {
    width: calc(75% - 2.4rem);
  }
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`

const Footerreact = () => {
  return (
    <ThemeProvider theme={theme}>
      <Span>
        <Wrapper>
          <SearchAndInfo>
            <SearchReference />
            <Container>
              <InfoPratique model={infos} />
              <InfoPratique model={servicesPro} />
              <InfoPratique model={sites} />
            </Container>
          </SearchAndInfo>
          <Socials />
          <LienSeo model={linksSeo} />
        </Wrapper>
      </Span>
    </ThemeProvider>
  );
};
export default Footerreact;
