import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "@ui-library/slbc";
import OppMandatCta from "./opportunite-mandat/cta";
import PreneurButtonFactory from "./opportunite-preneur";
import BroadcastClassifieds from "./diffuser-annonce";
import Serviciel from "./serviciel";
import { isMobile } from "src/js/utils/utils";
import { PreneurStepperContextProvider } from "./opportunite-preneur/context";
const PreneurButton = PreneurButtonFactory();

export default function Header() {
  const oppMandatCtaContainer = document.getElementById(
    "opportunite-mandat-react"
  );
  const servicielContainers = [
    ...document.querySelectorAll<HTMLElement>("[data-react-serviciel]"),
  ].map((el) => el);
  const broadcastContainer = document.getElementById(
    "data-react-broadcast-classifieds"
  );

  useEffect(() => {
    initMenu();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <PreneurStepperContextProvider>
        {!!oppMandatCtaContainer &&
          createPortal(
            <>
              <BroadcastClassifieds />
              <PreneurButton />
              <OppMandatCta />
            </>,
            oppMandatCtaContainer
          )}

        {servicielContainers.map((container) =>
          createPortal(<Serviciel />, container)
        )}
        {!!isMobile &&
          !!broadcastContainer &&
          createPortal(<BroadcastClassifieds />, broadcastContainer)}
      </PreneurStepperContextProvider>
    </ThemeProvider>
  );
}

export function initMenu() {
  const body = document.querySelector<HTMLElement>(".body-wrapper"); // ancien layout
  const footer = document.querySelector<HTMLElement>("#footer-essential-links"); // ancien layout
  const layout = document.getElementById("layout"); // nouveau layout
  const icon = document.getElementById("header-icon");
  const overlay = document.getElementById("header-overlay");
  const subnavContainer = document.querySelector<HTMLElement>(
    ".sub-nav-container"
  );
  const evts = [];

  icon.addEventListener("click", (e) => {
    evts.push(e);

    const navigation = document.querySelector<HTMLElement>("#navigation");
    const subnav = document.querySelector<HTMLElement>(`.sub-nav.opened`);
    const linkActived = document.querySelector<HTMLElement>(
      `[role="menuitem"].active`
    );

    if (
      !navigation.classList.contains("opened") &&
      !subnav?.classList.contains("opened")
    ) {
      navigation.classList.add("opened");
      body?.classList.add("hidden-for-header");
      footer?.classList.add("hidden-for-header");
      layout?.classList.add("show-header-only");
      icon.classList.add("closing");
      overlay?.classList.add("opened-mobile");
      document?.body.classList.add("show-header");
    } else {
      navigation.classList.remove("opened");
      subnav?.classList.remove("opened");
      subnavContainer?.classList.remove("opened");
      body?.classList.remove("hidden-for-header");
      footer?.classList.remove("hidden-for-header");
      layout?.classList.remove("show-header-only");
      icon.classList.remove("closing");
      linkActived?.classList.remove("active");
      overlay?.classList.remove("opened-mobile");
      document?.body.classList.remove("show-header");
    }
  });

  document.querySelectorAll<HTMLElement>('[role="menuitem"]').forEach((el) => {
    el.addEventListener("click", (e) => {
      evts.push(e);
      const id = el.dataset.navId;
      const subnav = document.querySelector<HTMLElement>(`#${id}.sub-nav`);
      const navigation = document.querySelector<HTMLElement>("#navigation");
      const subnavOpened = document.querySelector<HTMLElement>(
        `.sub-nav.opened`
      );
      const linkActived = document.querySelector<HTMLElement>(
        `[role="menuitem"].active`
      );

      if (!subnav.classList.contains("opened")) {
        subnav.classList.add("opened");
        subnavContainer?.classList.add("opened");
        navigation.classList.remove("opened");
        subnavOpened?.classList.remove("opened");
        overlay.classList.add("opened");
        overlay.style.height = `calc(${document.body.clientHeight}px - ${overlay.offsetTop}px)`;
        linkActived?.classList.remove("active");
        el.classList.add("active");
      }
    });
  });

  document.querySelectorAll<HTMLElement>(`.sub-nav`).forEach((el) => {
    el.querySelector<HTMLElement>("#back-btn").addEventListener("click", () => {
      const navigation = document.querySelector<HTMLElement>("#navigation");
      navigation.classList.add("opened");
      el.classList.remove("opened");
      subnavContainer?.classList.remove("opened");
    });
  });

  document
    .querySelector(".sub-nav-container")
    .addEventListener("click", (e) => {
      evts.push(e);
    });

  document.addEventListener("click", (e) => {
    if (!evts.find((_) => _ === e)) {
      // click en dehors du header
      document
        .querySelector<HTMLElement>(`.sub-nav.opened`)
        ?.classList.remove("opened");
      document
        .querySelector<HTMLElement>(`[role="menuitem"].active`)
        ?.classList.remove("active");
      overlay.classList.remove("opened");
    }
    evts.length = 0;
  });
}
