import { post } from "src/react/tools/asynchronous-proxies"
import { OppSeeker } from "src/react/types/seeker"
import { ListingCriteria } from "src/types/listing-criteria"

export const sendOppPreneurService = (data: OppSeeker) => {
  return post<unknown, OppSeeker>(`/api/contact/preneur`, {
    idTypeTransaction: data.idTypeTransaction,
    codeTypeBien: data.codeTypeBien,
    secteurActivite: data.secteurActivite,
    activite: data.activite,
    surface: data.surface,
    codeInsees: data.codeInsees,
    codeDepartements: data.codeDepartements,
    effectif: data.effectif,
    dateInstallation: data.dateInstallation,
    message: data.message,
    societe: data.societe,
    civilite: data.civilite,
    nom: data.nom,
    email: data.email,
    telephone: data.telephone,
  })
}

export const suscribeNewsletterService = (email: string) => {
  return post<unknown, { newsletterAdresse: string }>(
    `/api/newsletter/inscription`,
    {
      newsletterAdresse: email,
    }
  )
}

export type TypesuscribeAlertService = {
  mail: string
  checkedPartenaire: boolean
  checkedNewsletter: boolean
  criterias: ListingCriteria
}

export const suscribeAlertService = (mail: string, criterias: ListingCriteria) => {
  return post<
    unknown,
    TypesuscribeAlertService
  >(`/api/alerte/create`, {
    mail,
    criterias,
    checkedPartenaire: false,
    checkedNewsletter: false,
  })
}
