import React from 'react'
import styled from 'styled-components'
import { Button } from '@ui-library/core/Button'
import Wording from '../wording'

const DiffuserAnnonceContainer = styled.div`
  @media screen and (min-width: 992px) {
    margin-right: 1.2rem;
  }
`

const ButtonStyled = styled(Button)`
  font-size: 14px;
  color: #2B2B2B;
  border-color: #F1E8E1;
  &:hover {
    border-color: #F1E8E1 !important;
    color: #2B2B2B !important;
  }
  &:focus::before{
    background: #F1E8E1 !important;
  }

    height: auto;
    min-height: 32px;
  @media screen and (max-width: 1024px)
 {
    font-style: normal;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 3rem;
    color: #2B2B2B;
    padding: 1.2rem 0;
    flex-grow: 1;
    border: none;

    &:hover::before, &::before, &:focus::before {
      background: transparent !important;
    }
}
`

export default function BroadcastClassifieds() {
    return <DiffuserAnnonceContainer>
        <ButtonStyled
            data-test="space-botton"
            size="small"
            kind="secondary"
            variant="text"
            tag="button"
            onClick={() => window.location.href = `/diffusez-vos-annonces`}

        >
            {Wording.broadcastClassifieds}
        </ButtonStyled>
    </DiffuserAnnonceContainer>
}
