export const SecteurActivitesOptions = [
  {
    key: "1",
    label: "Alimentation",
    value: 1,
  },
  {
    key: "3",
    label: "Café - Hôtel - Restaurant",
    value: 3,
  },
  {
    key: "5",
    label: "Coiffure - Beauté - Bien-être",
    value: 5,
  },
  {
    key: "9",
    label: "Mode",
    value: 9,
  },
  {
    key: "4",
    label: "Commerces de proximité",
    value: 4,
  },
  ,
  {
    key: "11",
    label: "Services de proximité",
    value: 11,
  },
  ,
  {
    key: "6",
    label: "Equipement maison",
    value: 6,
  },
  ,
  {
    key: "10",
    label: "Santé",
    value: 10,
  },
  ,
  {
    key: "8",
    label: "Loisirs",
    value: 8,
  },
  ,
  {
    key: "2",
    label: "Automobile - Transport",
    value: 2,
  },
  ,
  {
    key: "7",
    label: "Informatique - Télécoms",
    value: 7,
  },
];

export const SecteurActivitesOptionsSorted = SecteurActivitesOptions.sort(
  (a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }
);

export const TypeBienValues: {
  None: 0;
  Bureaux: 800;
  Boutique: 600;
  Terrain: 400;
  VenteFondsCommerce: 900;
  LocalActiviteEtEntrepot: 750;
  Coworking: 1700;
} = {
  None: 0,
  Bureaux: 800,
  Boutique: 600,
  Terrain: 400,
  VenteFondsCommerce: 900,
  LocalActiviteEtEntrepot: 750,
  Coworking: 1700,
};

export const TypesBienOptions = [
  {
    key: "1",
    label: "Bureaux",
    value: TypeBienValues.Bureaux,
  },
  {
    key: "2",
    label: "Boutique / local commercial",
    value: TypeBienValues.Boutique,
  },
  {
    key: "3",
    label: "Local d activité / entrepôt",
    value: TypeBienValues.LocalActiviteEtEntrepot,
  },
  {
    key: "4",
    label: "Terrains",
    value: TypeBienValues.Terrain,
  },
  {
    key: "5",
    label: "Vente fonds de commerce",
    value: TypeBienValues.VenteFondsCommerce,
  },
];

export const SeekerTypesBienOptions = [
  {
    key: "1",
    label: "Bureaux",
    value: TypeBienValues.Bureaux,
  },
  {
    key: "2",
    label: "Boutique / local commercial",
    value: TypeBienValues.Boutique,
  },
  {
    key: "3",
    label: "Local d'activité / entrepôt",
    value: TypeBienValues.LocalActiviteEtEntrepot,
  },
  {
    key: "4",
    label: "Terrains",
    value: TypeBienValues.Terrain,
  },
];

export enum TypesEffectif {
  LESS_THAN_10 = 1,
  BETWEEN_11_AND_100 = 2,
  BETWEEN_101_AND_300 = 3,
  BETWEEN_301_AND_500 = 4,
  MORE_THAN_500 = 5,
}

export const EffectifOptions = [
  {
    key: "1",
    label: "Moins de 10",
    value: TypesEffectif.LESS_THAN_10,
  },
  {
    key: "2",
    label: "De 11 à 100",
    value: TypesEffectif.BETWEEN_11_AND_100,
  },
  {
    key: "3",
    label: "De 101 à 300",
    value: TypesEffectif.BETWEEN_101_AND_300,
  },
  {
    key: "4",
    label: "De 301 à 500",
    value: TypesEffectif.BETWEEN_301_AND_500,
  },
  {
    key: "5",
    label: "+ de 500",
    value: TypesEffectif.MORE_THAN_500,
  },
];

export enum TypeTransactionEnum {
  Location = 1,
  Vente = 2,
  VenteFondCommerce = 10,
}

export enum SeekerTypeTransactionEnum {
  Location = 1,
  Vente = 2,
  VenteFondCommerce = 10,
  CessionDeBail = 11,
}

export const TransactionsOptions = [
  { label: "Location", value: TypeTransactionEnum.Location, id: "1" },
  { label: "Achat", value: SeekerTypeTransactionEnum.Vente, id: "2" },
  {
    label: "Achat fond de commerce",
    value: SeekerTypeTransactionEnum.VenteFondCommerce,
    id: "3",
  },
  {
    label: "Cession de bail",
    value: SeekerTypeTransactionEnum.CessionDeBail,
    id: "4",
  },
];

export enum UnitePrixEnum {
  EuroAn = 1,
  EuroM2An = 2,
  EuroMois = 3,
  EuroM2 = 5,
  Euro = 6,
}

export enum CiviliteEnum {
  MR = 1,
  MRS = 2,
}

export const CiviliteOptions = [
  { label: "Monsieur", value: CiviliteEnum.MR, id: "1" },
  { label: "Madame", value: CiviliteEnum.MRS, id: "2" },
];
