import React, { lazy, Suspense } from 'react';
import { Modal } from 'src/react/common/modal';
const OppMandatStepperLazy = lazy(() => import('./stepper'));

const OppMandatStepper = (props: Parameters<typeof OppMandatStepperLazy>[0]) => <Suspense fallback=''><OppMandatStepperLazy {...props} /></Suspense>

export default function OppMandatModal({opened, onClose}: {
    opened: boolean;
    onClose: () => void;
}) {
    return <Modal onCloseModal={onClose} isVisible={opened} maxWidth='98.6rem' showHeader={true}>
        <OppMandatStepper onClose={onClose}/>
    </Modal>
}
