import { PreneurModel } from "src/types/container/preneur";
import {
  SET_FORM_STEP,
  SET_FORM_DATA,
  SET_FORM_RESULT,
  SET_OPEN_MODAL,
  RESET_MODAL_DATA,
  SET_ALERT_SUSCRIBE,
  SET_NEWSLETTERS_SUSCRIBE,
} from "./actions";

export type PreneurStepperType = {
  step: number;
  isFailed: boolean;
  isModalOpen: boolean;
  formData: PreneurModel;
  isNewsletterSuscribed: boolean;
  isAlertSuscribed: boolean;
};

export const initialState: PreneurStepperType = {
  step: 0,
  isFailed: false,
  isModalOpen: false,
  isNewsletterSuscribed: false,
  isAlertSuscribed: false,
  formData: {
    idTypeTransaction: 0,
    codeTypeBien: "",
    surface: "",
    localite: null,
    dateInstallation: null,
    effectif: null,
    activite: "",
    secteurActivite: "",
    message: "",
    nom: "",
    getSpecialist: false,
    errors: {
      dateInstallation: "",
      societe: "",
      civilite: "",
      nom: "",
      email: "",
      telephone: "",
      codeTypeBien: "",
      getSpecialist: "",
    },
  },
};
export const PreneurStepperReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_OPEN_MODAL: {
      return {
        ...state,
        isModalOpen: action.isModalOpen,
      };
    }
    case SET_FORM_STEP: {
      return {
        ...state,
        step: action.step,
      };
    }

    case SET_FORM_DATA: {
      return {
        ...state,
        formData: action.formData,
      };
    }
    case SET_FORM_RESULT: {
      return {
        ...state,
        isFailed: action.isFailed,
      };
    }
    case SET_ALERT_SUSCRIBE: {
      return {
        ...state,
        isAlertSuscribed: true,
      };
    }
    case SET_NEWSLETTERS_SUSCRIBE: {
      return {
        ...state,
        isNewsletterSuscribed: true,
      };
    }
    case SET_FORM_RESULT: {
      return {
        ...state,
        isFailed: action.isFailed,
      };
    }
    case RESET_MODAL_DATA: {
      return initialState;
    }

    default:
      return state;
  }
};
