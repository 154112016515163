import AdsManager from "./utils/ads-manager";

var ads = {
  initAds: function () {    
    var unitPathElem = document.querySelector("div[data-ad-unit-path]");
    if (unitPathElem) {
      var unitPath = unitPathElem.getAttribute("data-ad-unit-path");
      if (unitPath) {
        window.googletag = googletag || {cmd:[]};
        googletag.cmd.push(() => {
          window.AdsManager = new AdsManager(googletag);
          window.AdsManager.init(unitPath);
          window.AdsManager.addEvent([{
            eventName: "slotRenderEnded",
            events: [{
                slotId: "div-gpt-ad-banner-haute-habillage",
                eventName: "onMegaBannerLoaded"
              }, {
                slotId: "div-gpt-ad-native1",
                eventName: "onNativeLoaded"
              }, {
                slotId: "div-gpt-ad-native2",
                eventName: "onNativeLoaded"
              }, {
                slotId: "div-gpt-ad-native3",
                eventName: "onNativeLoaded"
              }, {
                slotId: "div-gpt-ad-native4",
                eventName: "onNativeLoaded"
              }, {
                slotId: "div-gpt-ad-native5",
                eventName: "onNativeLoaded"
              }
            ]
          }, {
            eventName: "slotRequested",
            events: [{
              slotId: "div-gpt-ad-banner-haute-habillage",
              eventName: "onMegaBannerSlotRequested"
            }]
          }, {
            eventName: "slotResponseReceived",
            events: [{
              slotId: "div-gpt-ad-banner-haute-habillage",
              eventName: "onMegaBannerResponseReceived"
            }]
          }, {
            eventName: "slotVisibilityChanged",
            events: [{
              slotId: "div-gpt-ad-banner-haute-habillage",
              eventName: "onMegaBannerVisibilityChanged"
            }]
          }]);
        });
      }
    }
  }
}

document.addEventListener('onMegaBannerSlotRequested', function () {
  // on fige la largeur et hauteur de l'élément pour éviter l'effet épileptique à la disparition puis réaparition de la pub
  const masterHeadContainer = document.querySelector("#master-head-ad-container");
  const height = masterHeadContainer.clientHeight;
  masterHeadContainer.style.height = height && `${height}px` || null;
})

let isMegaBannerResponseReceived = false;
document.addEventListener('onMegaBannerResponseReceived', function () {
  isMegaBannerResponseReceived = true;
})

document.addEventListener('onMegaBannerVisibilityChanged', function () {
  if (isMegaBannerResponseReceived) {
    isMegaBannerResponseReceived = false;
    const masterHeadContainer = document.querySelector("#master-head-ad-container");
    masterHeadContainer.style.height = null; 
  }
});

export default ads;
