import React, { lazy, Suspense } from "react";
import { Tracking } from "src/js/tracking/ga3/types";
import { TrackingZoneEnum } from "src/js/tracking/ga4/types/enums";
import styled from "styled-components";

const ServicielsFlex = styled.div`
  display: flex;
  width: 100%;
  max-width: 96rem;
  flex-direction: row;
  justify-content: space-between;
  & > div {
    width: calc(50% - 0.8rem);
  }
`;

const ServicielMandat = lazy(
  () =>
    import(
      /* webpackChunkName: "serviciel-mandat" */
      "./serviciel-mandat"
    )
);

const ServicielPreneur = lazy(
  () =>
    import(
      /* webpackChunkName: "serviciel-preneur" */
      "./serviciel-preneur"
    )
);

export default function ServicielButtons({
  intInfoPreneur,
  onOpenModal,
  intInfoMandat,
  isCssMobileVersion,
  cssBreakpoint,
  zone,
  ...props
}: {
  onOpenModal?: () => void;
  intInfoMandat: Tracking.OpportunityServicielIntInfo;
  intInfoPreneur: Tracking.OpportunityServicielIntInfo;
  className?: string;
  isCssMobileVersion?: boolean;
  cssBreakpoint?: number;
  zone?: TrackingZoneEnum
}) {
  return (
    <Suspense
      fallback={
        <p>
          En chargement.
          <br />
          Veuillez patienter...
        </p>
      }
    >
      <ServicielsFlex {...props}>
        <ServicielPreneur
          intInfo={intInfoPreneur}
          cssBreakpoint={cssBreakpoint}
          isCssMobileVersion={isCssMobileVersion}
          onOpenModal={onOpenModal}
          zone={zone}
        />
        <ServicielMandat
          intInfo={intInfoMandat}
          cssBreakpoint={cssBreakpoint}
          isCssMobileVersion={isCssMobileVersion}
          zone={zone}
        />
      </ServicielsFlex>
    </Suspense>
  );
}
