import MD5 from "crypto-js/md5";
import { TypeTransactionEnum } from "src/react/tools/constant";
import {CodeTypeBien, IdTypeTransaction, LeaseType, ListingClassifiedDocument, SubCoworkingType} from "src/types/classifieds";
import {ListingCriteria} from "../../../types/listing-criteria";
import {TrackingDistributionSubtype, TrackingTypeBienEnum} from "./types/enums";

export function cryptEmail(email: string) {
    return email ? MD5(email).toString() : undefined;
}

export function mapToDistributionTypeGlobal(typeTransaction: TypeTransactionEnum) {
    switch (typeTransaction) {
        case TypeTransactionEnum.Location:
            return 1;
        case TypeTransactionEnum.Vente:
        case TypeTransactionEnum.VenteFondCommerce: 
            return 2;
        default:
            return undefined;
    }
}

export function mapDistributionTypesGlobal(idTypeTransactions: IdTypeTransaction[]): string {
    return idTypeTransactions
        .map(l =>
            l !== IdTypeTransaction.FondDeCommerce ? l : IdTypeTransaction.Achat)
        .filter((value, index, self) => {
            return self.indexOf(value) === index;
        })
        .join('|')
}

export function mapDistributionSubTypes({
  idTypeTransactions,
  trading,
}: ListingCriteria): string {
  const result = new Set()

  for (let i = 0; i < idTypeTransactions.length; i++) {
    const t = idTypeTransactions[i]

    switch (t) {
      case IdTypeTransaction.Location:
        result.add(TrackingDistributionSubtype.LOCATION)
        break
      case IdTypeTransaction.Achat:
        result.add(TrackingDistributionSubtype.VENTE)
        break
      case IdTypeTransaction.FondDeCommerce:
        result.add
      default:
        break
    }
  }

  if (!trading?.excludeLeaseBack) {
    result.add(TrackingDistributionSubtype.CESSION_DE_BAIL)
  }

  if (!trading?.excludeAccessPrice) {
    result.add(TrackingDistributionSubtype.PAS_DE_PORTES)
  }

  return [...result].join("|")
}

export function mapEstateTypes(codeTypeBiens: CodeTypeBien[]): string {
    const result = new Set()
    for (const codeTypeBien of codeTypeBiens) {
        switch (codeTypeBien) {
            case CodeTypeBien.Terrain:
                result.add('av_9')
                break
            case CodeTypeBien.LocalActiviteEntrepot:
                result.add('av_12')
                break
            case CodeTypeBien.Boutique:
            case CodeTypeBien.LocalCommercial:
                result.add('av_13')
                break
            case CodeTypeBien.Coworking:
            case CodeTypeBien.Bureaux:
                result.add('av_7')
                break
            default:
                break
        }
    }

    return [...result].join("|")
}

export function mapEstateSubTypes({
                                      idTypeTransactions,
                                      codeTypeBiens,
                                      office,
                                  }: ListingCriteria): string {
    const result = new Set()

    if (idTypeTransactions.includes(IdTypeTransaction.FondDeCommerce)) {
        result.add(TrackingTypeBienEnum.BUSINESS_SOLD)

        if (idTypeTransactions.length === 1) {
            return [...result].join("|")
        }
    }

    for (const codeTypeBien of codeTypeBiens) {
        switch (codeTypeBien) {
            case CodeTypeBien.Terrain:
                result.add(TrackingTypeBienEnum.PLOT)
                break
            case CodeTypeBien.LocalCommercial:
            case CodeTypeBien.Boutique:
                result.add(TrackingTypeBienEnum.SHOP)
                result.add(TrackingTypeBienEnum.COMMERCIAL_SPACE)
                break
            case CodeTypeBien.LocalActiviteEntrepot:
                result.add(TrackingTypeBienEnum.STORAGE)
                break
            case CodeTypeBien.Bureaux:
                if (idTypeTransactions.includes(IdTypeTransaction.Achat) || !office?.leaseTypes?.length) {
                    result.add(TrackingTypeBienEnum.OFFICE)
                    break
                }

                for (const leaseType of office?.leaseTypes) {
                    switch (leaseType) {
                        case LeaseType.Classic369LeaseOffice:
                            result.add(TrackingTypeBienEnum.OFFICE_COMMERCIAL_LEASE)
                            break
                        case LeaseType.ShortLeaseOffice:
                            result.add(TrackingTypeBienEnum.OFFICE_SHORT_LEASE)
                            break
                        case LeaseType.ServicedOffice:
                            result.add(TrackingTypeBienEnum.OFFICE_SERVICED)
                            break
                        default:
                            result.add(TrackingTypeBienEnum.OFFICE)
                            break
                    }
                }
                break

            case CodeTypeBien.Coworking:
                result.add(TrackingTypeBienEnum.COWORKING_SPACE)
                break

            case CodeTypeBien.SubCoworking:
                if (!office?.subCoworkingTypes.length) {
                    result
                        .add(TrackingTypeBienEnum.COWORKING_WORKSTATION)
                        .add(TrackingTypeBienEnum.COWORKING_PRIVATE_WORKSTATION)
                        .add(TrackingTypeBienEnum.COWORKING_PRIVATE_OFFICE)
                    break
                }

                for (const subCoworkingType of office?.subCoworkingTypes) {
                    switch (subCoworkingType) {
                        case SubCoworkingType.Workstation:
                            result.add(TrackingTypeBienEnum.COWORKING_WORKSTATION)
                            break
                        case SubCoworkingType.PrivateWorkstation:
                            result.add(TrackingTypeBienEnum.COWORKING_PRIVATE_WORKSTATION)
                            break
                        case SubCoworkingType.PrivateOffice:
                            result.add(TrackingTypeBienEnum.COWORKING_PRIVATE_OFFICE)
                            break
                        default:
                            result.add(TrackingTypeBienEnum.COWORKING_SPACE)
                            break
                    }
                }
                break

            default:
                break
        }
    }

    return [...result].join("|")
}

export function mapDistributionSubType(classified: ListingClassifiedDocument): string {
    switch (classified.idTypeTransaction) {
      case IdTypeTransaction.Location:
        const distriSubType = []
        if (classified.price?.additionalCharges?.leaseRight) {
          distriSubType.push(`${TrackingDistributionSubtype.CESSION_DE_BAIL}`)
        }
        if (classified.price?.additionalCharges?.accessPrice) {
          distriSubType.push(`${TrackingDistributionSubtype.PAS_DE_PORTES}`)
        }
        if (distriSubType?.length > 0) {
          return distriSubType.join('|')
        }
        return `${TrackingDistributionSubtype.LOCATION}`
      case IdTypeTransaction.Achat:
        return `${TrackingDistributionSubtype.VENTE}`
      case IdTypeTransaction.FondDeCommerce:
        return `${TrackingDistributionSubtype.VENTE_FONDS_DE_COMMERCE}`
      default:
        return ''
    }
  }
  