export type AutocompleteLocalityType = {
    localisationType: AutocompleteLocalisationType
    queryKey: string;
    
    value: string;
    label: string;

    codePostal?: string

    tracking?: TrackingAutocompleteLocalityType
}

export enum AutocompleteLocalisationType {
    Pays = 1 << 0,
    Region = 1 << 1,
    Departement = 1 << 2,
    FausseVille = 1 << 3,
    Ville = 1 << 4,
    Quartier = 1 << 5,
    All = Pays | Region | Departement | FausseVille | Ville | Quartier
}

export type TrackingAutocompleteLocalityType = {
    region: string
    department?: string
    city?: string
    postalCode?: string
    district?: string
}