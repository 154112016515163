import React from "react";
import styled from "styled-components";
import ServicielButtons from "src/react/common/serviciels/components/serviciel-buttons";
import Tag from "@ui-library/core/Tag";
import Wording from "./wording";
import { usePreneurStepper } from "../opportunite-preneur/context";
import { TrackingZoneEnum } from "src/js/tracking/ga4/types/enums";

const Buttons = styled(ServicielButtons)`
  margin-bottom: 2.4rem;
`;

const ServicielsContainer = styled.div.attrs((props) => ({
  className: "serviciels-container",
}))`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  width: 100%;
  background-color: #fff;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #2b2b2b;
  margin: 2.4rem 0 1.2rem 0;
  @media screen and (min-width: 1025px) {
    margin-left: 0;
    margin-bottom: 1.6rem;
    margin-top: 0;
  }
`;

const Label = styled.span`
  display: none;
  margin-right: 1.6rem;
  @media screen and (min-width: 1025px) {
    display: inline;
  }
`;

const FreeServiceTag = styled(Tag)`
  font-weight: normal;
`;

export default function Serviciel() {
  const { onOpenModal } = usePreneurStepper();

  return (
    <ServicielsContainer>
      <Title>
        <Label>{Wording.ourServices}</Label>
        <FreeServiceTag label={Wording.freeServices} />
      </Title>
      <Buttons
        onOpenModal={onOpenModal}
        cssBreakpoint={1025}
        intInfoMandat="opportunity_seller_interest-mega_header"
        intInfoPreneur="opportunity_buyer_interest-mega_header"
        zone={TrackingZoneEnum.DETAIL_HEADER}
      />
    </ServicielsContainer>
  );
}
