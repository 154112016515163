export interface ListingClassifiedDocument {
  id: string
  idAnnonce: number
  detailUrl: string
  codeTypeBien: CodeTypeBien
  idTypeTransaction: IdTypeTransaction

  idTypeBien: number
  idSousTypeBien: number

  isAnnonceOr: boolean
  isBoostSuggestion: boolean
  isBoostHighlight: boolean
  isDivisible: boolean
  isImmediatelyAvailable: boolean

  businessSold?: BusinessSold
  coworking?: Coworking
  office?: Office
  subCoworking?: SubCoworking
  cardTitle: string
  cardDescription: string
  equipments: Equipment[]
  localisation: Localisation
  mandat: Mandat
  photos: string[]
  price?: Price
  publicationDateOnly: Date
  publicationDateTime: Date
  qualityIndex: number
  refAnnonce?: string
  space: number
  divisibility: Divisibility
}

export interface Mandat {
  isCoex: boolean
  isExclusive: boolean
  hasEmail: boolean
  hasTel: boolean
  mandataires: Mandataire[]
}

export interface Mandataire {
  name: string
  idAgence: number
  idRcu: string
  idTiers: number
  isPrincipal: boolean
  logoUrl: string
  websiteUrl: string
  codePostal: string
}

export interface Localisation {
  codeRegion?: number
  codeDepartement: string
  idSubDivision: number
  fakeCityCodeInsee: number
  isArrondissement: boolean
  codeInsee: number
  codePostal: string
  nomVille: string
  idQuartier: number
  nomQuartier: string
  idIris?: number
  adresse: string
  localisationPrecision: LocalisationPrecision
  geoLocation?: GeoLocalisation
}

export interface GeoLocalisation {
  latitude: number
  longitude: number
}

export interface Price {
  buy?: Buy
  rent?: Rent
  additionalCharges: AdditionalCharges
}

export interface Cost {
  type: CostType
  value: number
}

export interface Buy {
  price: Cost
  priceBySquareMeter: Cost
}

export interface Rent {
  monthlyPrice?: Cost
  monthlyPriceByDesk?: Cost
  yearlyPrice?: Cost
  yearlyPriceBySquareMeter?: Cost
}

export interface AdditionalCharges {
  isLeaseBack: boolean

  hasAgencyFeesIncluded?: boolean
  hasOperatingCostIncluded?: boolean
  hasTaxIncluded?: boolean

  accessPrice?: number
  leaseRight?: number
}

export interface BusinessSold {
  isWallsIncluded: boolean
  businessType: BusinessType
  subBusinessType: BusinessSubType
}

export interface CoworkingSubDetail {
  count: number
  startingMonthlyPrice?: number
}

export interface Coworking {
  workstation: CoworkingSubDetail
  privateWorkstation: CoworkingSubDetail
  privateOffice: CoworkingSubDetail
  meetingRoom: CoworkingSubDetail
}

export interface Office {
  leaseType?: LeaseType
  deskCount?: number
}

export interface SubCoworking {
  type: SubCoworkingType
  deskCount?: number
}

export interface Divisibility {
    spaceMaximum?: number
    spaceMinimum?: number
    subDivisionsCount?: number
}

export enum BusinessType {
  None = 0,
  FOOD = 1,
  TRANSPORT = 2,
  GASTRO_HOTEL = 3,
  LOCAL_SHOP = 4,
  WELLNESS_HEALTHCARE = 5,
  HOUSE_EQUIPMENT = 6,
  COMPUTER_TELECOM = 7,
  LEISURE = 8,
  FASHION = 9,
  HEALTH = 10,
  LOCAL = 11,
}

export enum BusinessSubType {
  None = 0,
  BUTCHER_SHOP = 1,
  BAKERY = 2,
  CHOCOLATES_CANDIES = 3,
  GROCERY = 4,
  CHEESE_DAIRY = 5,
  FISH_STORE = 6,
  GREENGROCERY = 7,
  CATERING = 8,
  WINE = 9,
  MINI_MARKET = 10,
  SUPERMARKET = 11,
  DRIVING_SCHOOL = 12,
  CAR_MOTORCYCLE_DEALER = 13,
  GARAGE_SERVICE_STATION = 14,
  CAFE_BAR_PUB = 15,
  CAMPING = 16,
  CREPERIE = 17,
  ICECREAM = 18,
  HOTEL = 19,
  PIZZERIA = 20,
  RESTAURANT = 21,
  FASTFOOD = 22,
  TEAROOM = 23,
  GIFT_SHOP = 24,
  FLORIST = 25,
  HABERDASHERY = 26,
  KIOSK = 27,
  HAIRDRESSING = 28,
  BEAUTY_STORE = 29,
  PERFUMERY = 30,
  FURNISHING = 31,
  HOUSEHOLD_APPLIANCES = 32,
  COMPUTER_SHOP_OFFICE_SUPPLY = 33,
  PHONES = 34,
  ARTS_MUSIC = 35,
  SPORTS_CLUB = 36,
  CLUB_DISCOTHEK = 37,
  ARTS_GALLERY = 38,
  BOOKS = 39,
  TOYS = 40,
  JEWELLERY = 41,
  SHOES = 42,
  LEATHER = 43,
  CLOTHES = 44,
  TAXI_AMBULANCE = 45,
  DENTAL = 46,
  PHYSIOTHERAPIST = 47,
  RETIREMENT_HOME = 48,
  OPTICAL = 49,
  PHARMACY = 50,
  REAL_ESTATE = 51,
  PETS = 52,
  DIY = 53,
  SHOE_REPAIR = 54,
  RELOCATION = 55,
  ELECTRICITY = 56,
  GARDENER = 57,
  LAUNDROMAT = 58,
  CARPENTRY = 59,
  PHOTO = 60,
  PLUMBING_HEATING = 61,
  LAUNDRY = 62,
  LOCKSMITH = 63,
  GLASSMAKING = 64,
  TRAVEL_AGENCY = 65,
}

export enum CodeTypeBien {
  Terrain = 400,
  Boutique = 600,
  LocalCommercial = 700,
  LocalActiviteEntrepot = 750,
  Bureaux = 800,
  Coworking = 1700,
  SubCoworking = 1800,
}

export enum IdTypeTransaction {
  Location = 1,
  Achat = 2,
  FondDeCommerce = 10,
}

export enum LocalisationPrecision {
  Inconnue = 0,
  Exact = 1,
  Iris = 2,
  Quartier = 3,
  Ville = 4,
}

export enum Equipment {
  None = 0,
  Parking = 1,
  Terrasse = 2,
  Vitrine = 3,
  QuaiDeChargement = 4,
  LogementAttenant = 5,
  EspaceDeStockage = 6,
  Climatisation = 7,
  PMR = 8
}

export enum LeaseType {
  Classic369LeaseOffice = 1,
  ShortLeaseOffice = 2,
  ServicedOffice = 3,
}

export enum SubCoworkingType {
  Workstation = 1,
  PrivateWorkstation = 2,
  PrivateOffice = 3,
  MeetingRoom = 4
}

export enum CostType {
    None = 0,
    Global = 1,
    Average = 2,
    Min = 3,
    Max = 4,
}
