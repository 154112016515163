import {
  trackingMapAgencyDetailProduct,
  trackingMapAgencyProduct,
} from "./ga3/utils/agencies/product"
import { trackingMapAgencySearch } from "./ga3/utils/agencies/search"
import {
  AgencyDetailResponse,
  SearchAgencyResponse,
} from "./ga3/utils/agencies/type"
import {
  trackingMapCoworkingDetailClassifiedProduct,
  trackingMapDetailClassifiedProducts,
} from "./ga3/utils/classifieds/product"
import { CoworkingDetailDataLayer } from "./ga3/utils/classifieds/type"
import { sendGA4PageView, sendViewCommon } from "./ga4/view"
import {
  mapAgencyDirectoryPageGA4,
  mapClassifiedDetailPageGA4,
} from "./ga4/utils/page/classified"
import { Item, PageCategory, Search } from "@sl/react-thor"
import { trackingMapAgencyItem } from "./ga4/utils/agencies/product"
import { mapAgencySearchGa4 } from "./ga4/utils/agencies/search"
import {
  sendPageView,
  trackOppPreneurStepFiveView,
  trackOppPreneurStepFourthView,
  trackOppPreneurStepOneView,
  trackOppPreneurStepThirdView,
  trackOppPreneurStepTwoView,
  trackOpportunityMandatStep1View,
  trackOpportunityMandatStep2View,
  trackOpportunityMandatStep3View,
  trackOpportunityMandatStep4View,
  trackOpportunityMandatStep5View,
} from "src/js/tracking/ga3/tracking-wrapper"
import {
  sendEventSellerOpportunityStep1,
  sendEventSellerOpportunityStep2,
  sendEventSellerOpportunityStep3,
  sendEventSellerOpportunityStep4,
  sendEventSellerOpportunityStep5,
  sendEventSellerOpportunityStep6,
} from "./ga4/events/opportunity/seller"
import {
  sendEventSeekerOpportunityStep1,
  sendEventSeekerOpportunityStep2,
  sendEventSeekerOpportunityStep3,
  sendEventSeekerOpportunityStep4,
  sendEventSeekerOpportunityStep5,
  sendEventSeekerOpportunityStep6,
} from "./ga4/events/opportunity/seeker"
import { TrackingTypeBienEnum } from "./ga3/types/enums"
import { PreneurStepperType } from "src/react/common/header/opportunite-preneur/context/reducer"
import { MandatModel } from "src/types/container/mandat"
import { AutocompleteLocalityType } from "src/react/common/locality-autocomplete/types"


export function sendViewDetailCoworking(dataLayer: CoworkingDetailDataLayer) {
  const products = trackingMapCoworkingDetailClassifiedProduct(dataLayer)

  sendPageView({
    pagename: "detail-coworking",
    products,
  })
}

export function sendViewAgencyDirectory(response: SearchAgencyResponse) {
  const products = response.results.map(trackingMapAgencyProduct)
  const search = trackingMapAgencySearch(response)
  // GA4
  const productsGA4 = response.results.map(trackingMapAgencyItem)
  const searchGA4 = mapAgencySearchGa4(response)
  const pageGA4 = mapAgencyDirectoryPageGA4(response?.autocompleteLocalites ?? [])

  sendPageView({
    pagename: "search_results_client",
    search,
    products,
  })

  sendGA4PageView({
    ...pageGA4,
    av_search: searchGA4 as unknown as Search,
    av_items: productsGA4 as unknown as Item[],
  })
}

export function sendViewAgencyDirectoryDetail(dataLayer: AgencyDetailResponse) {
  const products = [trackingMapAgencyDetailProduct(dataLayer.listingAgency)]

  sendPageView({
    pagename: "detail_client",
    products,
  })
}

export function sendViewError(statusCode: number) {
  sendPageView({
    pagename: statusCode === 404 ? "error_page_404" : "error_page_500",
  })
  sendGA4PageView({
    av_pagetitle: `error_page_${statusCode}`,
    av_category: 'error',
    av_error_code: `${statusCode}`,
  })
}

export function sendViewMyAccount() {
  // GA4
  sendGA4PageView({
    av_pagetitle: "alert_unsubscription",
    av_category: "alert" as unknown as PageCategory,
  })
}


export function sendViewSeekerOpportunity(state: PreneurStepperType) {
  switch (state.step) {
    case 0:
      sendEventSeekerOpportunityStep1()
      break
    case 1:
      trackOppPreneurStepOneView()
      sendEventSeekerOpportunityStep2()
      break
    case 2:
      trackOppPreneurStepTwoView()
      sendEventSeekerOpportunityStep3(state.formData)
      break
    case 3:
      trackOppPreneurStepThirdView(
        state.formData.codeTypeBien as unknown as TrackingTypeBienEnum
      )
      sendEventSeekerOpportunityStep4(state.formData)
      break
    case 4:
      trackOppPreneurStepFourthView(
        state.formData.codeTypeBien as unknown as TrackingTypeBienEnum,
        state.formData?.localite
          ?.map((localite: AutocompleteLocalityType) => localite.value)
          .join("|")
      )
      sendEventSeekerOpportunityStep5()
      break
    case 5:
      if (!state.isFailed) {
        trackOppPreneurStepFiveView(
          state.formData.codeTypeBien as unknown as TrackingTypeBienEnum,
          state.formData?.localite
            ?.map((localite: AutocompleteLocalityType) => localite.value)
            .join("|")
        )
        sendEventSeekerOpportunityStep6(state.formData)
      }
      break
    default:
      null
  }
}

export function sendViewSellerOpportunity(step: number, model: MandatModel) {
  switch (step) {
    case 0:
      sendEventSellerOpportunityStep1()
      break
    case 1:
      trackOpportunityMandatStep1View()
      sendEventSellerOpportunityStep2()
      break
    case 2:
      trackOpportunityMandatStep2View()
      sendEventSellerOpportunityStep3(model)
      break
    case 3:
      trackOpportunityMandatStep3View(model.typeBien)
      sendEventSellerOpportunityStep4(model)
      break
    case 4:
      trackOpportunityMandatStep4View(
        model.typeBien,
        model.localite?.codePostal
      )
      sendEventSellerOpportunityStep5()
      break
    case 5:
      if (!model.isFailed) {
        trackOpportunityMandatStep5View(
          model.typeBien,
          model.localite?.codePostal
        )
        sendEventSellerOpportunityStep6(model)
        break
      }
  }
}



