import { BusinessSubType, BusinessType, CodeTypeBien, Equipment, IdTypeTransaction, LeaseType, SubCoworkingType } from "./classifieds"

export type ListingCriteria = {
  codeTypeBiens: CodeTypeBien[]
  idTypeTransactions: IdTypeTransaction[]
  localisations?: LocalisationCriteria[]
  boundary?: BoundaryCriteria
  // geoDistance?: GeoDistanceCriteria // back only
  budget?: BudgetCriteria
  space?: MinMaxCriteria
  keywords?: string[]
  equipments?: Equipment[]
  idAgences?: number[]
  idClassifiedsExcluded?: number[]
  reference?: string
  sort?: SortCriteria
  trading?: TradingCriteria
  office?: OfficeCriteria
  // size?: number // back only
  page?: number
}

export type LocalisationCriteria = {
  type: LocalisationTypeCriteria
  code: string | number
}

export enum LocalisationTypeCriteria {
  Unkown = 0,
  Iris = 1,
  District = 2,
  City = 3,
  FakeCity = 4,
  Department = 5,
  Region = 6
}

export type BoundaryCriteria = {
  east: number
  north: number
  south: number
  west: number
}

export type MinMaxCriteria = {
  min?: number
  max?: number
}

export type PriceCriteria = MinMaxCriteria & {
  unit: PriceUnitCriteria
}

export type BudgetCriteria = {
  buy?: PriceCriteria
  rent?: PriceCriteria
}

export enum PriceUnitCriteria {
  None = 0,
  RentAnnual = 1,
  RentAnnualBySquareMeter = 2,
  RentMonthly = 3,
  BuyBySquareMeter = 5,
  Buy = 6,
  RentMonthlyByDesk = 7,
}

export enum SortCriteria {
  Recommended = 0,
  Latest = 1,
  LowestPrice = 2,
  HighestPrice = 3,
  LowestSpace = 4,
  HighestSpace = 5,
  // ClosestToFurthest = 6, // back only
}

export type TradingCriteria = {
  excludeLeaseBack: boolean
  excludeAccessPrice: boolean
  excludeWallsBuy: boolean
  excludeWallsRent: boolean
  businessType: BusinessTypeCriteria[]
}

export type BusinessTypeCriteria = {
  type: BusinessType
  subTypes: BusinessSubType[]
}

export type OfficeCriteria = {
  leaseTypes: LeaseType[]
  subCoworkingTypes?: SubCoworkingType[]
  desk?: MinMaxCriteria
}
