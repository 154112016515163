import { TrackingEstateTypeEnum, TrackingEstateTypeEnums, TrackingTypeBienEnums, TrackingTypeBienEnum } from "src/js/tracking/ga3/types/enums";

export function getEstateTypeFromTypeBien(typeBien: TrackingTypeBienEnum): TrackingEstateTypeEnum {
    switch (typeBien) {
        case TrackingTypeBienEnums.Boutique: return TrackingEstateTypeEnums.Boutique;
        case TrackingTypeBienEnums.Bureaux: return TrackingEstateTypeEnums.Bureaux;
        case TrackingTypeBienEnums.Local: return TrackingEstateTypeEnums.Locaux;
        case TrackingTypeBienEnums.Entrepot: return TrackingEstateTypeEnums.Entrepot;
        case TrackingTypeBienEnums.Terrain: return TrackingEstateTypeEnums.Terrain;
        case TrackingTypeBienEnums.VenteFondCommerce: return TrackingEstateTypeEnums.VenteFondCommerce;
    }
}
