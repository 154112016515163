import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { trackNavigationOpportunityPreneurInteraction } from "src/js/tracking/ga3/tracking-wrapper";
import { AutocompleteLocalisationType, AutocompleteLocalityType } from "src/react/common/locality-autocomplete/types";
import { useMobile } from "src/react/hooks/use-match-media";
import {
  readOppPreneur,
  writeOppPreneur,
} from "src/react/services/storage-service"
import { SeekerTypeTransactionEnum, TypeBienValues } from "src/react/tools/constant";
import { CodeTypeBien, IdTypeTransaction } from "src/types/classifieds";
import { PreneurModel } from "src/types/container/preneur";
import { ListingCriteria, LocalisationTypeCriteria } from "src/types/listing-criteria";
import { suscribeAlertService, suscribeNewsletterService } from "../services";
import {
  RESET_MODAL_DATA,
  SET_ALERT_SUSCRIBE,
  SET_FORM_DATA,
  SET_FORM_RESULT,
  SET_FORM_STEP,
  SET_NEWSLETTERS_SUSCRIBE,
  SET_OPEN_MODAL,
} from "./actions";
import { initialState, PreneurStepperReducer } from "./reducer";
import {
  handleSubmitFirstStep,
  handleSubmitFourthStep,
  handleSubmitSecondStep,
  handleSubmitThirdStep,
} from "./utils";

import { sendViewSeekerOpportunity } from "src/js/tracking";
import { sendEventSeekerOpportunity } from "src/js/tracking/ga4/events/opportunity/seeker"
import { ElementZone } from "src/js/tracking/ga4/types";

type PreneurStepperContextType = {
  onSubmitResultForm: (value: boolean) => void;
  onSuscribeNewsletter: () => void;
  onSuscribeAlert: () => void;
  onChangeStep: (value: number) => void;
  onChangeData: (data: PreneurModel) => void;
  onOpenModal: (zone?: ElementZone) => void;
  onFinishForm: () => void;
  step: number;
  isFailed: boolean;
  isModalOpen: boolean;
  formData: PreneurModel;
  isNewsletterSuscribed: boolean;
  isAlertSuscribed: boolean;
};

export const PreneurStepperContext = createContext<PreneurStepperContextType>(
  (null as unknown) as PreneurStepperContextType
);

export function usePreneurStepper() {
  return useContext(PreneurStepperContext);
}

function getAlerteQuery(
  typeTransaction: SeekerTypeTransactionEnum,
  typeBien: number,
  surfMin: number,
  localities: AutocompleteLocalityType[]
): ListingCriteria {
  const codeTypeBiens: CodeTypeBien[] = []
  const idTypeTransactions: IdTypeTransaction[] = []

  switch (typeBien) {
    case TypeBienValues.Boutique:
    case TypeBienValues.VenteFondsCommerce:
      codeTypeBiens.push(CodeTypeBien.Boutique)
      codeTypeBiens.push(CodeTypeBien.LocalCommercial)
      break
    case TypeBienValues.Bureaux:
      codeTypeBiens.push(CodeTypeBien.Bureaux)
      break
    case TypeBienValues.LocalActiviteEtEntrepot:
      codeTypeBiens.push(CodeTypeBien.LocalActiviteEntrepot)
      break
    case TypeBienValues.Terrain:
      codeTypeBiens.push(CodeTypeBien.Terrain)
      break
    default:
      break
  }

  switch (typeTransaction) {
    case SeekerTypeTransactionEnum.CessionDeBail:
    case SeekerTypeTransactionEnum.Location:
      idTypeTransactions.push(IdTypeTransaction.Location)
      break
    case 20 as any:
      idTypeTransactions.push(IdTypeTransaction.Location)
      idTypeTransactions.push(IdTypeTransaction.Achat)
      break
    case SeekerTypeTransactionEnum.Vente:
      idTypeTransactions.push(IdTypeTransaction.Achat)
      break
    case SeekerTypeTransactionEnum.VenteFondCommerce:
      idTypeTransactions.push(IdTypeTransaction.FondDeCommerce)
      break
    default:
      break
  }

  if (idTypeTransactions.length === 0 || codeTypeBiens.length === 0) {
    return undefined
  }

  return {
    idTypeTransactions,
    codeTypeBiens,
    space: surfMin && {
      min: surfMin
    },
    localisations: localities
      .filter(item => item.localisationType === AutocompleteLocalisationType.Ville
        || item.localisationType === AutocompleteLocalisationType.Departement
      )
      .map(l => ({
        code: l.value,
        type: l.localisationType === AutocompleteLocalisationType.Ville
          ? LocalisationTypeCriteria.City
          : LocalisationTypeCriteria.Department
      }))
  }
}

export const PreneurStepperContextProvider: React.FC<PreneurStepperProviderProps> = (
  props
) => {
  const [state, dispatch] = useReducer(PreneurStepperReducer, initialState);
  const { children } = props;
  const isMobile = useMobile();

  // // // Reset data
  useEffect(() => {
    const initialValue = readOppPreneur();
    let localite = initialState.formData.localite;
    let codeTypeBien = initialState.formData.codeTypeBien;
    let surface = initialState.formData.surface;
    let idTypeTransaction = initialState.formData.idTypeTransaction;
    let secteurActivite = initialState.formData.secteurActivite;

    const criterias = (window as any)?.confierRechercheCriterias
    if (criterias) {
      if (criterias.CodeInsee && criterias.Localites) {
        localite = criterias.Localites;
      }
      if (criterias.Surface) {
        surface = criterias.Surface;
      }
      if (criterias.CodeTypeBien) {
        codeTypeBien = criterias.CodeTypeBien;
      }
      if (criterias.IdTypeTransaction) {
        idTypeTransaction = criterias.IdTypeTransaction;
      }
      if (criterias.IdSecteurActivite) {
        secteurActivite = criterias.IdSecteurActivite;
      }
    }

    dispatch({ type: SET_FORM_STEP, step: 0 });
    dispatch({
      type: SET_FORM_DATA,
      formData: {
        ...initialValue,
        localite,
        surface,
        codeTypeBien,
        idTypeTransaction,
        secteurActivite,
      },
    });
  }, []);

  // Trigger overflow - glitch for modal - rewritten on next js stack
  useEffect(() => {
    const modal = document.getElementById("modal-dialog");
    if (modal) {
      if (state.step === 1 && !isMobile) modal.style.overflow = "initial";
      else modal.style.overflow = "inherit";
    }
  }, [state.step, isMobile]);

  // Trigger tracking GA4
  useEffect(() => {
    if (state.isModalOpen) {
      sendViewSeekerOpportunity(state)
    }
  }, [state.isModalOpen, state.step]);

  const onChangeStep = (step: number) => {
    if (step < 0 || step > 6) return;
    if (step === 0 || step === 1)
      return dispatch({ type: SET_FORM_STEP, step });
    if (step === 2)
      return handleSubmitFirstStep(dispatch, state.formData, step);
    if (step === 3)
      return handleSubmitSecondStep(dispatch, state.formData, step);
    if (step === 4)
      return handleSubmitThirdStep(dispatch, state.formData, step);
    if (step === 5)
      return handleSubmitFourthStep(dispatch, state.formData, step);
    writeOppPreneur(state.formData);
  };

  const onSuscribeNewsletter = async () => {
    try {
      await suscribeNewsletterService(state.formData.email);
      dispatch({ type: SET_NEWSLETTERS_SUSCRIBE });
    } catch (err) {
      console.log(err);
    }
  };

  const onSuscribeAlert = async () => {
    const query = getAlerteQuery(
      state.formData.idTypeTransaction,
      state.formData.codeTypeBien,
      state.formData.surface,
      state.formData?.localite
    );
    try {
      await suscribeAlertService(state.formData.email, query);
      dispatch({ type: SET_ALERT_SUSCRIBE });
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeData = (formData: PreneurModel) => {
    dispatch({ type: SET_FORM_DATA, formData });
  };

  const onOpenModal = (zone?: ElementZone) => {
    if (!state.isModalOpen) {
      trackNavigationOpportunityPreneurInteraction();
      sendEventSeekerOpportunity(zone);
    }
    dispatch({ type: SET_OPEN_MODAL, isModalOpen: !state.isModalOpen });
  };

  const onFinishForm = () => {
    onOpenModal();
    dispatch({ type: RESET_MODAL_DATA });
  };

  const onSubmitResultForm = (value: boolean) => {
    dispatch({ type: SET_FORM_RESULT, isFailed: value });
  };
  return (
    <PreneurStepperContext.Provider
      value={{
        onSubmitResultForm,
        onSuscribeNewsletter,
        onSuscribeAlert,
        onChangeStep,
        onChangeData,
        onOpenModal,
        onFinishForm,
        ...state,
      }}
    >
      {children}
    </PreneurStepperContext.Provider>
  );
};

export type PreneurStepperProviderProps = {
  children: ReactNode;
};
