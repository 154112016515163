import thor from "@sl/thor"
import { defaultGa4MetasParams } from "../../view"
import { MandatModel } from "src/types/container/mandat"
import { CodeTypeBien } from "src/types/classifieds"
import { mapEstateType } from "../../utils/classifieds/product"
import { cryptEmail, mapToDistributionTypeGlobal } from "../../tracking-tool"
import { TypeTransactionEnum } from "src/react/tools/constant"
import { ElementZone } from "../../types"
import { TrackingZoneEnum } from "../../types/enums"

export function sendEventSellerOpportunity(av_zone: ElementZone = TrackingZoneEnum.HEADER) {
  thor.pushEvent("entrust_your_property", {
    av_category: "classified",
    av_zone,
  })
}

export function sendEventSellerOpportunityStep1() {
  thor.pushEvent("page_view", {
    ...defaultGa4MetasParams,
    av_pagetitle: "entrust_your_property_landing",
    av_category: "classified",
  })
}

export function sendEventSellerOpportunityStep2() {
  thor.pushEvent("page_view", {
    ...defaultGa4MetasParams,
    av_pagetitle: "entrust_your_property_1_estate_type",
    av_category: "classified",
  })
}

export function sendEventSellerOpportunityStep3(data: MandatModel) {
  thor.pushEvent("page_view", {
    ...defaultGa4MetasParams,
    av_pagetitle: "entrust_your_property_2_description",
    av_category: "classified",
    av_estate_type_global: mapEstateType(
      data.typeBien as unknown as CodeTypeBien
    ),
    av_distribution_type_global: mapToDistributionTypeGlobal(
      data?.transaction as TypeTransactionEnum
    )?.toString(),
    av_distribution_subtype: data?.transaction?.toString(),
    av_search: {
      business_type: data?.secteurActivite?.toString() ?? undefined,
    },
  })
}

export function sendEventSellerOpportunityStep4(data: MandatModel) {
  thor.pushEvent("page_view", {
    ...defaultGa4MetasParams,
    av_pagetitle: "entrust_your_property_3_description",
    av_category: "classified",
    av_city: data?.localite?.label,
  })
}

export function sendEventSellerOpportunityStep5() {
  thor.pushEvent("page_view", {
    ...defaultGa4MetasParams,
    av_pagetitle: "entrust_your_property_4_contact",
    av_category: "classified",
  })
}

export function sendEventSellerOpportunityStep6(data: MandatModel) {
  thor.pushEvent("page_view", {
    ...defaultGa4MetasParams,
    av_pagetitle: "entrust_your_property_5_confirmation",
    av_category: "classified",
    av_estate_type_global: mapEstateType(
      data.typeBien as unknown as CodeTypeBien
    ),
    av_distribution_type_global: mapToDistributionTypeGlobal(
      data?.transaction as TypeTransactionEnum
    )?.toString(),
    av_distribution_subtype: data?.transaction?.toString(),
    av_city: data?.localite?.label,
    av_search: {
      business_type: data?.secteurActivite?.toString(),
    },
    email_account: cryptEmail(data?.email ?? ""),
  })
}
