import { trackingMapClassifiedProduct, trackingMapClassifiedProducts } from "src/js/tracking/ga3/utils/classifieds/product";
import { ListingClassifiedDocument } from "src/types/classifieds";
import { Tracking } from "src/js/tracking/ga3/types";
import { TrackingTypeBienEnum } from "src/js/tracking/ga3/types/enums";
import {
  buildALISubscriptionAutoInteraction,
  buildALISubscriptionManualInteraction,
  buildBoostSuggestionClickInteraction,
  buildBoostSuggestionInteraction,
  buildCoworkingContactClickInteraction,
  buildCoworkingContactConfirmation,
  buildCoworkingContactConversion,
  buildCoworkingPhonePostLeadConversion,
  buildDetailItemPricesInteraction,
  buildLinkToAgencyWebsiteInteraction,
  buildMailConfirmationInteraction,
  buildMailConversionInteraction,
  buildMailInterestInteraction,
  buildNavigationAgrandissementCartoInteraction,
  buildNavigationBarMobileInteraction,
  buildNavigationMapListCartoInteraction,
  buildNavigationOpportunityMandatInteraction,
  buildNavigationOpportunityPreneurInteraction,
  buildOpportunityExpressSubmitInteraction,
  buildOpportunityMandatInteraction,
  buildOpportunityMandatStep1View,
  buildOpportunityMandatStep2View,
  buildOpportunityMandatStep3View,
  buildOpportunityMandatStep4View,
  buildOpportunityMandatStep5View,
  buildOpportunityMandatSubmitInteraction,
  buildOpportunityPreneurLeadmailInteraction,
  buildOpportunityPreneurSubmitInteraction,
  buildOpportunityPreneurView,
  buildOpportunityServicielInteraction,
  buildPhoneConversionInteraction,
  buildTrackingFormErrorInteraction,
  buildTrackingNavigationProductInteraction,
} from "./tracking-factory";
import { getEstateTypeFromTypeBien } from "./tracking-reference";
import { send } from "./tracking-sender";
import { cryptEmail } from "./tracking-tool";

let TRACKING_PAGE_DATA: Tracking.TrackingView = {
  event: 'view',
  language: 'fr',
  site: 'SLBC',
  environment: document.location.host === 'www.seloger-bureaux-commerces.com'
    ? 'prod'
    : 'stage',
  pagename: '',
  products: [],
  url_path: document.location.pathname,
  referrer: document.referrer.toString()
}

export function initPageTracking(containerId: string) {
  (function (w, d, s, l, i) {
    let source = `https://www.googletagmanager.com`;
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    var f = d.getElementsByTagName(s)[0],
      j: any = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = source + "/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", containerId);
}

export function sendPageView(value: Partial<Tracking.TrackingView>) {
  TRACKING_PAGE_DATA = {
    ...TRACKING_PAGE_DATA,
    ...value
  }
  send({ event: "view", ...TRACKING_PAGE_DATA });
}

export function trackNavigationAnnonce(
  infos: Tracking.TrackingNavigationProductInteractionIntInfo,
  idannonce: number
): void {
  const annonce = TRACKING_PAGE_DATA.products.filter(
    (_) => _.id === idannonce
  )[0];
  send(buildTrackingNavigationProductInteraction(infos, [annonce]));
}

export function trackMailAnnonceInterest(
  infos: Tracking.MailInterestInteractionIntInfo,
  idannonce: number
): void {
  const annonce = TRACKING_PAGE_DATA.products.filter(
    (_) => _.id === idannonce
  )[0];
  send(buildMailInterestInteraction(infos, [annonce]));
}

export function trackPhoneAnnonceConversion(
  infos: Tracking.PhoneConversionInteractionIntInfo,
  idannonce: number,
): void {
  const annonce = TRACKING_PAGE_DATA.products.filter(
    (_) => _.id === idannonce
  )[0];
  send(buildPhoneConversionInteraction(infos, [annonce]));
}

export function trackALISubscriptionAuto(
  infos: Tracking.ALISubscriptionAutoInteractionIntInfo,
  email: string
): void {
  send(buildALISubscriptionAutoInteraction(infos, cryptEmail(email)));
}

export function trackALISubscriptionManual(
  infos: Tracking.ALISubscriptionManualInteractionIntInfo,
  email: string
): void {
  send(buildALISubscriptionManualInteraction(infos, cryptEmail(email)));
}

export function trackMailAnnonceConversion(
  infos: Tracking.MailConversionInteractionIntInfo,
  email: string,
  idannonce: number,
  idagence: number,
): void {
  const annonce = TRACKING_PAGE_DATA.products.filter(
    (_) => _.id === idannonce
  )[0];
  send(
    buildMailConversionInteraction(infos, cryptEmail(email), [
      { ...annonce, idagence: `${idagence}` },
    ])
  );
}

export function trackMailAnnonceConfirmation(
  infos: Tracking.MailConfirmationInteractionIntInfo,
  email: string,
  idannonce: number,
  idagence: number
): void {
  const annonce = TRACKING_PAGE_DATA.products.filter(
    (_) => _.id === idannonce
  )[0];
  send(
    buildMailConfirmationInteraction(infos, cryptEmail(email), [
      { ...annonce, idagence: `${idagence}` },
    ])
  );
}

export function trackNavigationAgence(
  infos: Tracking.TrackingNavigationProductInteractionIntInfo,
  idagence: number
): void {
  const agence = TRACKING_PAGE_DATA.products.filter(
    (_) => +_.idagence === idagence
  )[0];
  send(buildTrackingNavigationProductInteraction(infos, [agence]));
}

export function trackMailAgenceInterest(
  infos: Tracking.MailInterestInteractionIntInfo,
  idagence: number
): void {
  const agence = TRACKING_PAGE_DATA.products.filter(
    (_) => +_.idagence === idagence
  )[0];
  send(buildMailInterestInteraction(infos, [agence]));
}

export function trackPhoneAgenceConversion(
  infos: Tracking.PhoneConversionInteractionIntInfo,
  idagence: number,
): void {
  const agence = TRACKING_PAGE_DATA.products.filter(
    (_) => +_.idagence === idagence
  )[0];
  send(buildPhoneConversionInteraction(infos, [agence]));
}

export function trackMailAgenceConversion(
  infos: Tracking.MailConversionInteractionIntInfo,
  email: string,
  idagence: number
): void {
  const agence = TRACKING_PAGE_DATA.products.filter(
    (_) => +_.idagence === idagence
  )[0];
  send(buildMailConversionInteraction(infos, cryptEmail(email), [agence]));
}

export function trackMailAgenceConfirmation(
  infos: Tracking.MailConfirmationInteractionIntInfo,
  email: string,
  idagence: number
): void {
  const agence = TRACKING_PAGE_DATA.products.filter(
    (_) => +_.idagence === idagence
  )[0];
  send(buildMailConfirmationInteraction(infos, cryptEmail(email), [agence]));
}

export function trackLinkToAgencyWebsite(
  infos: Tracking.LinkToAgencyWebsiteInteractionIntInfo,
  id: number
): void {
  const agence = TRACKING_PAGE_DATA.products.filter(
    (_) => +_.idagence === id
  )[0];
  send(buildLinkToAgencyWebsiteInteraction(infos, [agence]));
}

export function trackOpportunityPreneurLeadmailInteraction(
  infos: Tracking.OpportunityPreneurLeadmailInteractionIntInfo,
  email: string
) {
  send(buildOpportunityPreneurLeadmailInteraction(infos, cryptEmail(email)));
}

export function trackOpportunityExpressSubmitInteraction(
  email: string,
  products: Tracking.TrackingProductOpportuniteExpress[]
) {
  send(buildOpportunityExpressSubmitInteraction(cryptEmail(email), products));
}
export function trackOpportunityMandatStep1View(): void {
  send(buildOpportunityMandatStep1View(TRACKING_PAGE_DATA));
}

export function trackOpportunityMandatStep2View(): void {
  send(buildOpportunityMandatStep2View(TRACKING_PAGE_DATA));
}

export function trackOpportunityMandatStep3View(
  estateType: TrackingTypeBienEnum
): void {
  send(buildOpportunityMandatStep3View(TRACKING_PAGE_DATA, getEstateTypeFromTypeBien(estateType)))
}

export function trackOpportunityMandatStep4View(
  estateType: TrackingTypeBienEnum,
  codePostal: string
): void {
  send(
    buildOpportunityMandatStep4View(
      TRACKING_PAGE_DATA,
      getEstateTypeFromTypeBien(estateType),
      codePostal
    )
  );
}

export function trackOpportunityMandatStep5View(
  estateType: TrackingTypeBienEnum,
  codePostal: string
): void {
  send(
    buildOpportunityMandatStep5View(
      TRACKING_PAGE_DATA,
      getEstateTypeFromTypeBien(estateType),
      codePostal
    )
  );
}

export function trackOpportunityMandatSubmitInteraction(email: string) {
  send(buildOpportunityMandatSubmitInteraction(cryptEmail(email)));
}

export function trackJeConfieMonBien() {
  send(buildOpportunityMandatInteraction());
}

export function trackOpportunityMandatFormErrorInteraction(error: string) {
  send(buildTrackingFormErrorInteraction("opportunity_seller", error));
}

export function trackNavigationOpportunityMandatInteraction() {
  send(buildNavigationOpportunityMandatInteraction());
}

export function trackOpportunityExpressFormErrorInteraction(error: string) {
  send(buildTrackingFormErrorInteraction("opportunity_buyer", error));
}

export function trackContactFormErrorInteraction(error: string) {
  send(buildTrackingFormErrorInteraction("contact", error));
}

export function trackNavigationAgrandissementCartoInteraction(
  isEnlarge: boolean
) {
  send(buildNavigationAgrandissementCartoInteraction(isEnlarge));
}

export function trackNavigationMapListCartoInteraction(isMap: boolean) {
  send(buildNavigationMapListCartoInteraction(isMap));
}

export function trackNavigationBarMobileInteraction(
  intInfo: Tracking.NavigationBarMobileIntInfo
) {
  send(buildNavigationBarMobileInteraction(intInfo));
}

export function trackDetailItemPricesInteraction(
  infos: Tracking.DetailItemPricesIntInfo
) {
  send(buildDetailItemPricesInteraction(infos));
}

export function trackOpportunityServicielInteraction(
  infos: Tracking.OpportunityServicielIntInfo
) {
  send(buildOpportunityServicielInteraction(infos));
}

export function trackBoostSuggestionInteraction(
  sliderPage: number,
  products: ListingClassifiedDocument[]
) {
  const trackingProducts = trackingMapClassifiedProducts(products)
  send(
    buildBoostSuggestionInteraction(
      TRACKING_PAGE_DATA,
      sliderPage,
      trackingProducts
    )
  )
}

export function trackBoostSuggestionClickInteraction(
  product: ListingClassifiedDocument,
  position: number
) {
  const trackingProduct = trackingMapClassifiedProduct(product)
  trackingProduct.position = position
  send(buildBoostSuggestionClickInteraction([trackingProduct]))
}


export function trackCoworkingContact(
  type: Tracking.CoworkingContactType,
  infos: Tracking.CoworkingContactClickInteractionIntInfos
): void {
  const products = TRACKING_PAGE_DATA.products;
  send(buildCoworkingContactClickInteraction(infos, type, products));
}

export function trackCoworkingContactConversion(
  email: string,
  infos: Tracking.CoworkingContactClickInteractionIntInfos
): void {
  const products = TRACKING_PAGE_DATA.products;
  send(buildCoworkingContactConversion(infos, cryptEmail(email), products))
}

export function trackCoworkingContactConfirmation(
  email: string,
  infos: Tracking.CoworkingContactClickInteractionIntInfos
): void {
  const products = TRACKING_PAGE_DATA.products;
  send(buildCoworkingContactConfirmation(infos, cryptEmail(email), products))
}

export function trackCoworkingPhonePostLeadConversion(
  email: string,
  infos: Tracking.CoworkingContactClickInteractionIntInfos
): void {
  const products = TRACKING_PAGE_DATA.products;
  send(buildCoworkingPhonePostLeadConversion(infos, cryptEmail(email), products))
}

export function trackOppPreneurStepOneView(): void {
  send(buildOpportunityPreneurView(TRACKING_PAGE_DATA, 1, {}));
}

export function trackOppPreneurStepTwoView(): void {
  send(buildOpportunityPreneurView(TRACKING_PAGE_DATA, 2, {}));
}

export function trackOppPreneurStepThirdView(
  estateType: TrackingTypeBienEnum
): void {
  const extraData = { form_estate_type: getEstateTypeFromTypeBien(estateType) };
  send(buildOpportunityPreneurView(TRACKING_PAGE_DATA, 3, extraData));
}

export function trackOppPreneurStepFourthView(
  estateType: TrackingTypeBienEnum,
  codePostal: string
): void {
  const product = {
    list_name: "funnel_preneur",
    position: 0,
    client_id: null,
    id_rcu: null,
    idtiers: null,
    idagence: null,
    client_postalcode: null,
    country: null,
    client_type: null,
    business_type: null,
    parent_site: null,
    product_status: null,
    product_type: "estate",
  };

  const extraData = {
    form_estate_type: getEstateTypeFromTypeBien(estateType),
    form_postalcode: codePostal,
    product: [product],
  };
  send(buildOpportunityPreneurView(TRACKING_PAGE_DATA, 4, extraData));
}

export function trackOppPreneurStepFiveView(
  estateType: TrackingTypeBienEnum,
  codePostal: string
): void {
  const extraData = {
    form_estate_type: getEstateTypeFromTypeBien(estateType),
    form_postalcode: codePostal,
  };
  send(buildOpportunityPreneurView(TRACKING_PAGE_DATA, 5, extraData));
}

export function trackOppPreneurSubmitInteraction(email: string) {
  send(buildOpportunityPreneurSubmitInteraction(cryptEmail(email)));
}

export function trackOppPreneurFormErrorInteraction(email: string) {
  const error = email;
  send(buildTrackingFormErrorInteraction("opportunity_seller", error));
}

export function trackNavigationOpportunityPreneurInteraction() {
  send(
    buildNavigationOpportunityPreneurInteraction("opportunity_buyer_interest")
  );
}
