export async function get<T>(url: string, signal?: AbortSignal): Promise<T> {
    const response = await fetch(url, { signal });
    if (!response.ok) {
        throw {
            "errorStatus": response.status,
        };
    }
    
    try {
        const text = await response.text();
        if (text) {
            return JSON.parse(text);
        }

        return null;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function post<TResult, TData>(url: string, data: TData): Promise<TResult | null> {
    const headers = { 'content-type': 'application/json' } 
    const option: RequestInit = { method: 'POST', body: JSON.stringify(data), headers };
    const response = await fetch(url, option);
    if (!response.ok) {
        throw {
            "errorStatus": response.status,
        };
    }
    try {
        const text = await response.text();
        if (text) {
            return JSON.parse(text);
        }

        return null;
    } catch (e) {
        console.log(e);
        return null;
    }
}