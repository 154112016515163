import { useEffect, useState } from "react";

export function useMatchMedia(query: string): boolean {
	let [state, setState] = useState(() => window.matchMedia(query)?.matches || false);
	useEffect(() => {
		const mediaQuery = window.matchMedia(query);
		const handler = (ev: MediaQueryListEvent) => {
			setState(ev.matches || false);
		}

		mediaQuery.addEventListener('change', handler);


		return () => {
			mediaQuery.removeEventListener('change', handler);
		}
	}, []);

	return state;
}

export function useSmallDesktop() {
	return useMatchMedia('(min-width: 768px) and (max-width: 1279px)');
}

export function useMobile() {
	return useMatchMedia('(max-width: 767px)');
}

export function useLargeDesktop() {
	return useMatchMedia('(min-width: 1280px)');
}