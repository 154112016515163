import React, {useEffect } from 'react';
import styled from 'styled-components';
const Div = styled.div`
  @media screen and (min-width: 992px) {
    padding-top: 3.2rem;
  }
  @media screen and (max-width: 991px) {
    padding-top: 2.4rem;
  }
`;
const Nav = styled.nav`
  display: block;
`;
const Ul = styled.ul`
  margin: 0 auto;
  @media screen and (min-width: 992px) {
    padding: 0 1.6rem;
    text-align: center;
    max-width: 1120px;
  }
  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;
const Li = styled.li`
  display: inline;
  &:not(:last-of-type)::after {
    content: ' - ';
    color: white;
  }
`;
const A = styled.a`
  color: white;
  text-decoration: none;
  background-color: transparent;
  &:hover {
    color: white;
  }
`;
function LienSeo({model}:{model: footerreact.FooterModel}) {
  const data = model.links || [];
  useEffect(() => {
    var essentialLink = document.getElementById('footer-essential-links');
    var slGroupLinks = document.getElementById('sites-groupe-sl');
    var seoLinks = document.getElementById('seo-links');
    if(slGroupLinks)
    {
        essentialLink.removeChild(slGroupLinks);
        essentialLink.removeChild(seoLinks);
    }
  }, [])
  return (
    <Div>
      <div>
        <Nav>
          <Ul>
            {data.map(function (link) {
              return (
                <Li key={link.id}>
                  <A href={link.href}>{link.value}</A>
                </Li>
              );
            })}
          </Ul>
        </Nav>
      </div>
    </Div>
  );
}
export default LienSeo;
