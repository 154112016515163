import * as React from 'react';
import styled from 'styled-components';
const Div1 = styled.div`
  z-index: 0;
  @media screen and (min-width: 992px) {
    width: 25%;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
    padding-top: 3.2rem;
  }
`;
const Form = styled.form`
  display: block;
  margin-top: 0em;
`;
const Label = styled.label`
  font-size: 1.6rem;
  font-weight: 700;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
`;
const Div = styled.div`
  width: 100%;
  display: table;
  border-collapse: separate;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  z-index: 1;
`;
const Input = styled.input`
  height: 4rem;
  padding: 0.2rem 1.2rem;
  border: 0;
  border-radius: 3px 4px 4px 3px;
  outline: 0;
  box-shadow: none;
  resize: none;
  display: block;
  width: 100%;
  line-height: 1.375;
  color: #6e6c6c;
  background-color: #fff;
  background-image: none;
  font: inherit;
  margin: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`;
const Button = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
  padding: 1rem 1.2rem;
  background-color: #0F495F;
  border: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  outline: none !important;
  transition: background-color 0.2s, color 0.2s;
`;
const Img = styled.img`
  margin-left: -0.1rem;
  margin-top: -0.6rem;
  width: 2rem;
  height: 2rem;
  border: 0;
`;
const SearchReference = () => {
	const [reference, setReference] = React.useState<string>("");
	const [errorMessage, setErrorMessage] = React.useState<string>();

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setErrorMessage(undefined);
		setReference(e.target.value);
	}

	const formHandler = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!reference?.trim()) {
			setErrorMessage("Saisissez une référence");
			return;
		}

		fetch(`/api/referenceapi?refAnnonce=${encodeURIComponent(reference)}`)
			.then(response => {
				if (!response.ok) {
					setReference("");
					setErrorMessage("Une erreur est survenue veuillez réessayer");
					return;
				}

				response.json()
					.then(total => {
						if (total < 1 || total == null) {
							setReference("");
							setErrorMessage("Référence non valide");
							return;
						}

						location.href = `/recherche?refAnnonce=${encodeURIComponent(reference)}`;
					})
			})
	}

	return (
		<Div1>
			<Form action="/recherche" method="get" onSubmit={formHandler}>
				<Label>Rechercher une annonce par sa référence ?</Label>
				<Div>
					<Input
						type="text"
						className={errorMessage ? "has-error" : ""}
						placeholder={errorMessage ?? "N° de référence"}
						name="refAnnonce"
						value={reference}
						onChange={onInputChange}
						id="refAnnonce"
					/>
					<Button id="search-reference" type="submit">
						<Img
							src="/Content/App/dist/img/user-interface/loupe.svg"
							alt="rechercher"
							aria-label="lancer la recherche"
						/>
					</Button>
				</Div>
			</Form>
		</Div1>
	);
};
export default SearchReference;
