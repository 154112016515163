import * as React from 'react';
import styled from 'styled-components';
import { Icon } from '@ui-library/core/Icon';
import LinkedinSquareFilled from '@ui-library/icons/LinkedinSquareFilled';
import FacebookSquareFilled from '@ui-library/icons/FacebookSquareFilled';
import TwitterSquareFilled from '@ui-library/icons/TwitterSquareFilled';

const Span = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.6rem;
  @media screen and (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    padding-top: 2.4rem;
  }
`;
const StyledIcon = styled(Icon)`
  @media screen and (min-width: 426px) {
    margin-left: 1.6rem;
  }
  margin: 0.7rem 0.8rem 1.1rem;
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
`;
const SpanIcon = styled.span`
  @media screen and (max-width: 425px) and (max-width: 991px) {
    display: block;
  }
`;
const SpanSocials = styled.div`
  font-weight: 700;
  @media screen and (max-width: 991px) {
    width: 100%;
    margin-top: 0.4rem;
  }
  @media screen and (max-width: 425px) and (max-width: 991px) {
    text-align: center;
  }
`;

const SocialLink = styled.a`
  & , &:hover, &:focus {
    color: inherit;
  }
`;

const Socials = () => {
  return (
    <Span>
      <SpanSocials>
        <span>Suivre SeLoger bureaux & commerces</span>
        <SpanIcon>
         <SocialLink href="https://www.linkedin.com/company/seloger-bureaux-commerces/" target="_blank"><StyledIcon icon={LinkedinSquareFilled} size="40px" /></SocialLink>
         <SocialLink href="https://www.facebook.com/SeLogerBUCOM/" target="_blank"><StyledIcon icon={FacebookSquareFilled} size="40px" /></SocialLink>
         <SocialLink href="https://twitter.com/SeLogerBUCOM/" target="_blank"><StyledIcon icon={TwitterSquareFilled} size="40px" /></SocialLink>
        </SpanIcon>
      </SpanSocials>
    </Span>
  );
};
export default Socials;
