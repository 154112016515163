export function getUrlParameter(sParam) {
    let sPageURL,
        sURLVariables,
        sParameterName,
        i;
    if (window.location.hash.indexOf("?") == 1) { // cas des urls avec .com/#?...
        sPageURL = decodeURIComponent(window.location.hash.substring(2));
    }
    else if (window.location.search) { // cas des urls avec .com/?...
        sPageURL = decodeURIComponent(window.location.search.substring(1));
    }

    if (sPageURL) {
        sURLVariables = sPageURL.split('&');

        for (let i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    }
}

export function isMobile() {
    return window.matchMedia("(max-width:991px)").matches;
}

export function isTablet() {
    return window.matchMedia("(min-width:992px) and (max-width:1024px)").matches;
}

export function isMobileDevice() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)) {
        return true;
    }
    return false;
}

/* Format phone numbers, handling cases where there are multiple */
export function formatPhoneNumbers(telNumbers: string): string {
    let telNums = telNumbers.split('/');
    let formattedTelNumbers = telNums[0];
    if (telNums.length > 1) {
        formattedTelNumbers = '';
        for (let i = 0; i < telNums.length; i++) {
            formattedTelNumbers += telNums[i] + '<br/>';
        }
    }
    return formattedTelNumbers;
}

export function loadScript(url, callback) {
    // Adding the script tag to the head as suggested before
    let head = document.head;
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    // Then bind the event to the callback function.
    // There are several events for cross browser compatibility.
    script.onload = callback;
    // Fire the loading
    head.appendChild(script);
}
