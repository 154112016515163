import styled from "styled-components";
import { Button } from "@ui-library/core/Button";

export const PreneurOpportunityContainer = styled.div`
  position: relative;
`;

export const PreneurOpportunityButton = styled(Button).attrs(() => ({
  className: "link-action-header opportunite js-confiez-recherche",
  "data-test": "openLaasPreneurModal",
}))`
  @media screen and (max-width: 1023px) {
    margin-bottom: 12rem;
  }
  height: auto;
  min-height: 3.2rem;
`;

export const FormErrorMessage = styled.div`
  color: #c52a2a;
  margin-top: 0.8rem;
`;


export const HiddenButton = styled.button`
  display: none;
`;
