import { useEffect, useState } from "react";

export function useViewportHeight() {
    const [height, setHeight] = useState(window?.innerHeight && `${window.innerHeight}px` || '100vh');

    useEffect(() => {
        const callback = () => {
            setHeight(window?.innerHeight && `${window.innerHeight}px` || '100vh')
        }
        window?.addEventListener && window.addEventListener('resize', callback)
        return () => window?.removeEventListener && window.removeEventListener('resize', callback);
    }, []);

    return height;
}