export interface TrackingExtendedProduct extends ReactThor.Product {
  id_rcu?: string
  direct_availability?: 1 | 0
  business_type?: string
  'space-coworking_type'?: number
}

export interface TrackingExtendedSearch extends Omit<ReactThor.ThorViewSearch, 'advanced_criteria' | 'distribution_type'> {
  business_type?: string
  advanced_criteria?: string
  advanced_criteria_distribution_type?: string
  advanced_criteria_business_type?: string
  distribution_type?: number
  lease_price_min?: number
  lease_price_max?: number
  unit_price?: string
}

export enum TrackingTypeBienEnum {
  PLOT = 4,
  SHOP = 6,
  STORAGE = 7,
  COMMERCIAL_SPACE = 7.1,
  OFFICE = 8,
  OFFICE_COMMERCIAL_LEASE = 8.1,
  OFFICE_SHORT_LEASE = 8.2,
  OFFICE_SERVICED = 8.3,
  COWORKING_SPACE = 18,
  COWORKING_WORKSTATION = 18.2,
  COWORKING_PRIVATE_WORKSTATION = 18.5,
  COWORKING_PRIVATE_OFFICE = 18.3,
  BUSINESS_SOLD = 31,
}

export module ReactThor {
  export declare type LocationType = "district" | "city" | "department" | "region" | "country" | string;
  export declare type EstateType = 1 | 2 | 2.27 | 2.28 | 2.42 | 2.52 | 2.66 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18;
  export declare type DistributionType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 9.1 | 9.2 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20;
  export declare type InvestmentType = 4 | 8 | 16 | 32 | 64 | 128 | 256 | 1024 | 2048 | 4096 | 32768 | 65536 | 131072 | 262144;
  export declare type EnergyLetter = "A" | "B" | "C" | "D" | "E" | "F" | "G" | string;
  export declare type ProductStatus = "client_logo" | "exclusivity" | "featured" | "gold" | "silver" | "geolocation" | "boost";
  export declare type Websites = "SL" | "SLC" | "SLN" | "SLV" | "AMV" | "VCOM" | "SLBC" | "LCI" | "BD" | "LICOM" | "LINEUF" | "LIN" | "LICONSTRUIRE" | "LIC" | "LUX" | "LR" | "SL-Pro" | "SL-Mobile" | "SLN-Mobile" | "BD-Mobile" | "LICOM-Mobile" | "IWT" | "INT" | "IWB" | "MA" | "BUCOM";
  export declare type ItemName = "agency" | "classified" | "service" | "sold_property";
  export declare type EstateSubtype = "HUNTING" | "AGRICULTURE_COMPANY" | "FARM_RANCH" | "FISHING" | "FORESTRY" | "GARDENING" | "MISC_AGRICULTURE" | "ORCHARD" | "RIDING" | "WINERY" | "APARTMENT" | "ATTIKA" | "DUPLEX_OR_TRIPLEX" | "GALLERY" | "GROUNDFLOOR" | "HALF_BASEMENT" | "LOFT" | "PENTHOUSE" | "ROOF_STOREY" | "SINGLEROOM" | "TERRACE" | "APART_HOTEL" | "CAFE_BAR_PUB" | "CLUB_DISCOTHEQUE" | "HOTEL" | "RESTAURANT" | "GITE" | "APARTMENT_HOUSE" | "BEACH_HOUSE" | "BUNGALOW" | "CASTLE_MANOR_HOUSE" | "CHALET" | "CORNER_TERRACE_HOUSE" | "END_TERRACE_HOUSE" | "FARMHOUSE" | "FINCA" | "GARDEN_HOUSE" | "LIVING_AND_COMMERCIAL" | "MOUNTAIN_HUT" | "MULTI_FAMILY_HOUSE" | "RESIDENTIAL_COMPLEX" | "RUSTICO" | "SEMIDETACHED_HOUSE" | "SINGLE_FAMILY_HOUSE" | "SPECIAL_REAL_ESTATE" | "TERRACE_HOUSE" | "TOWN_HOUSE" | "VILLA" | "ATELIER" | "COWORKING" | "LIVING_AND_COMMERCIAL_BUILDING" | "MEDICAL" | "MEDICAL_BUILDING" | "OFFICE_BUILDING" | "OFFICE_CENTRE" | "OFFICE_SPACE" | "OFFICE_STORAGE_BUILDING" | "OPEN_SPACE" | "SHARED_OFFICE" | "SINGLE_OFFICE" | "BOAT_DOCK" | "CAR_PARK" | "CARPORT" | "DOUBLE_GARAGE" | "DUPLEX" | "GARAGE" | "GARAGE_AREA" | "OUTSIDE" | "PARKING_AREA" | "STREET_PARKING" | "UNDERGROUND_GARAGE" | "UNDERGROUND_PARKING_SPACE" | "AGRICULTURE_FORESTRY" | "COMMERCIAL" | "COMMERCIAL_PARC" | "INDUSTRY" | "LAKESIDE_PROPERTY" | "LEISURE_FACILITY" | "LIVING" | "MIXED" | "SPECIAL_USE" | "HOUSE_PARK" | "MISCELLANEOUS" | "MULTI_FAMILY_HOUSE" | "PLANED" | "SINGLE_MULTI_HOUSES" | "ASSISTED_LIVING" | "LIVING" | "MEDICAL_CARE";
  export declare type PromotionType = "standard" | "premium" | "boost_extend" | "gold" | "exclusivity" | string;
  export declare type BuildingState = "FIRST_TIME_USE" | "FIRST_TIME_USE_AFTER_REFURBISHMENT" | "FULLY_RENOVATED" | "MINT_CONDITION" | "MODERNISED" | "NEED_OF_RENOVATION" | "NEGOTIABLE" | "NO_INFORMATION" | "PARTLY_RENOVATED" | "PROJECTED" | "REFURBISHED" | "RESTRUCTURED" | "RIPE_FOR_DEMOLITION" | "WELL_KEPT";
  export declare type Sorting = "date" | "relevance" | "price_low_to_high" | "price_high_to_low" | "postal_code" | "rooms" | "surface_largest_to_smallest" | "surface_smallest_to_largest" | "recommendation" | "delivery_date_asc" | "delivery_date_desc";
  export declare type PageCategory = "homepage" | "prices" | "estimate" | "estimation_funnel" | "find_pro" | "classified" | "error" | "account" | "editorial" | "finance" | "sold_propertied" | "sold_properties_gov" | "price_map" | "other";

  export interface Product {
    /** Product ID (example: 3456576) */
    id: number;
    /** Name of the list containing product(s) (example: List) */
    list_name?: string;
    /** Indictes the position of the product in a list (example: 2) */
    position?: number;
    /** Indicates the price of the product (€), rent or buy (example: 20000) */
    price?: number;
    /** Index property type (example: 1) */
    estate_type?: string;
    /** Index of transaction type (cf. mapping) (example: 1) */
    distribution_type?: DistributionType;
    /** Indicates the surface (m²) (example: 20) */
    space?: number;
    /** Legacy client id (example: 34030) */
    idtiers?: number;
    /** Legacy client id (example: 34030) */
    idagence?: number;
    /** Investment type (example: [65536, 4096]) */
    investment_type?: string;
    /** Number of photos available (example: 1) */
    photos_nb?: number;
    /** Postal code (example: "75018") */
    estate_postalcode?: string;
    /** Client ID (agency) (example: 34030) */
    client_id?: number;
    /** Postal code (example: "75018") */
    client_postalcode?: string;
    /** Client type of the product (example: pro) */
    client_type?: string;
    /** Country of the product (example: FRA) */
    country?: string;
    /** Website's name of the product source (example: BD) */
    parent_site?: string;
    /** Construction or delivery year of the product (example: 2030) */
    year_of_construction?: number;
    /** Floor of the product (example: 2) */
    floor_nb?: number;
    /** Number of rooms (example: 2) */
    nb_rooms?: number;
    /** Number of bedrooms (example: 2) */
    nb_bedrooms?: number;
    /** Heating type (cf. mapping) (example: central) */
    heating_type?: string;
    /** Energy Performance Certificate (example: A) */
    energy_letter?: EnergyLetter;
    /** Indicates the business value of the product (example: boost|geolocation) */
    product_status?: ProductStatus | string;
    /** Indicates if the product contains interactive content (360°) (example: 0) */
    media?: 1 | 0;
    /** Type of product (estate, client) (example: estate) */
    product_type?: string;
  }

  export interface ThorViewSearchLocation {
    /** Country searched. Should be standardized with other location criterias (example: FRA) */
    country?: string[] | string;
    /** Region serched. Should be standardized with other location criterias (example: Ile-de-france) */
    region?: string[] | string;
    /** Department searched. Should be standardized with other location criterias (example: 75) */
    department?: string[] | string;
    /** City searched. Should be standardized with other location criterias (example: Paris) */
    city?: string[] | string;
    /** Disctrict searched. Should be standardized with other location criterias (example: Goutte d'Or-Château Rouge Paris 18ème) */
    district?: string[] | string;
    /** Postal code searched. Should be standardized with other location criterias (example [75018]) */
    postalcode?: string[] | string;
    /** Is search based on a city ? a department ? a disctrict ? by country ? (example: city|city|district|region|country|department) */
    location_type?: LocationType[] | string;
    /** Exact search terms displayed on screen (example: Paris|Vincennes) */
    location_keywords?: string;
  }
  export interface ThorViewSearch {
    /** Minimal budget searched (€) (example: 100000) */
    price_min?: number;
    /** Maximal budget searched (€) (example: 100000) */
    price_max?: number;
    /** Estate space minimal size searched (m²) (example: 100) */
    estate_space_min?: number;
    /** Estate space maximal size searched (m²) (example: 200) */
    estate_space_max?: number;
    /** Plot space minimal size searched (m²) (example: 100)*/
    estate_plot_min?: number;
    /** Plot space maximal size searched (m²) (example: 200)*/
    estate_plot_max?: number;
    /** Index of property type (cf. mapping) (example: "1|2")*/
    estate_type?: string;
    /** Index of distribution type (cf. mapping) (example: 2)*/
    distribution_type?: DistributionType;
    /** Investment type searched (example: "1")*/
    investment_type?: string;
    /** Typology of search (example: location, zone, traject-duration, geolocation, map_location) */
    search_type?: string;
    location?: ThorViewSearchLocation;
    /** Filter used (example: prix croissant) */
    sorting?: string;
    /** Indicates if the user used advanced criterias */
    advanced_criteria?: number;
    /** Number of search results */
    results_nb?: number;
    /** Current result page */
    page_nb?: number;
    /** Indicates the number of rooms searched */
    room_nb?: string | number;
    /** Indicates the number of bedrooms searched */
    bedroom_nb?: string | number;
    /** Indicates the number of people */
    people_nb?: string | number;
    /** Indicates which type of client is searched (example: pro) */
    client_type?: string;
    /** Indicates the type of refine used (example: refine_price|refine_location) */
    refine_type?: string;
  }

}
