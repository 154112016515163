import { Tracking } from "src/js/tracking/ga3/types";
import { TrackingEstateTypeEnum } from "src/js/tracking/ga3/types/enums";

export function buildTrackingNavigationProductInteraction(
  infos: Tracking.TrackingNavigationProductInteractionIntInfo,
  products: Tracking.TrackingProduct[]
): Tracking.TrackingNavigationProductInteraction {
  return {
    event: "interaction",
    int_type: "product",
    int_description: "click",
    int_infos: infos,
    products: products,
  };
}

export function buildTrackingFormErrorInteraction(
  formname: string,
  error: string
): Tracking.TrackingFormErrorInteraction {
  return {
    event: "interaction",
    int_type: "form-error",
    int_description: error,
    int_infos: formname,
  };
}

export function buildMailInterestInteraction(
  infos: Tracking.MailInterestInteractionIntInfo,
  products: Tracking.TrackingProduct[]
): Tracking.MailInterestInteraction {
  return {
    event: "interaction",
    int_type: "mail",
    int_description: "interest",
    int_infos: infos,
    products: products,
  };
}

export function buildMailConversionInteraction(
  infos: Tracking.MailConversionInteractionIntInfo,
  email: string,
  products: Tracking.TrackingProduct[],
): Tracking.MailConversionInteraction  & { abtest: string} {
  return {
    event: "interaction",
    int_type: "mail",
    int_description: "conversion",
    int_infos: infos,
    email_lead: email,
    products: products,
    abtest: "SLBC-Contact_leads"
  };
}

export function buildMailConfirmationInteraction(
  infos: Tracking.MailConfirmationInteractionIntInfo,
  email: string,
  products: Tracking.TrackingProduct[]
): Tracking.MailConfirmationInteraction {
  return {
    event: "interaction",
    int_type: "mail",
    int_description: "confirmation",
    int_infos: infos,
    email_lead: email,
    products: products,
  };
}

export function buildPhoneConversionInteraction(
  infos: Tracking.PhoneConversionInteractionIntInfo,
  products: Tracking.TrackingProduct[],
): Tracking.PhoneConversionInteraction & { abtest: string} {
  
  return {
    event: "interaction",
    int_type: "phone",
    int_description: "conversion",
    int_infos: infos,
    products: products,
    abtest: "SLBC-Contact_leads"
  };
}

export function buildALISubscriptionInterestInteraction(
  infos: Tracking.ALISubscriptionInterestInteractionIntInfo
): Tracking.ALISubscriptionInterestInteraction {
  return {
    event: "interaction",
    int_type: "ali",
    int_description: "interest",
    int_infos: infos,
  };
}

export function buildALISubscriptionManualInteraction(
  infos: Tracking.ALISubscriptionManualInteractionIntInfo,
  email: string
): Tracking.ALISubscriptionManualInteraction {
  return {
    event: "interaction",
    int_type: "ali",
    int_description: "subscription",
    int_infos: infos,
    email_lead: email,
  };
}

export function buildALISubscriptionAutoInteraction(
  infos: Tracking.ALISubscriptionAutoInteractionIntInfo,
  email: string
): Tracking.ALISubscriptionAutoInteraction {
  return {
    event: "interaction",
    int_type: "ali",
    int_description: "subscription",
    int_infos: infos,
    email_lead: email,
  };
}

export function buildLinkToAgencyWebsiteInteraction(
  infos: Tracking.LinkToAgencyWebsiteInteractionIntInfo,
  products: Tracking.TrackingProduct[]
): Tracking.LinkToAgencyWebsiteInteraction {
  return {
    event: "interaction",
    int_type: "navigation",
    int_description: "click",
    int_infos: infos,
    products: products,
  };
}

export function buildOpportunityExpressSubmitInteraction(
  email: string,
  products: Tracking.TrackingProductOpportuniteExpress[]
): Tracking.OpportunityExpressSubmitInteraction {
  return {
    event: "interaction",
    int_type: "mail",
    int_description: "opportunity_buyer_submit",
    int_infos: "search_results-bottom",
    email_lead: email,
    form_profile: "buyer",
    products: products.map((_) => ({ ..._, product_type: "client" })),
  };
}

export function buildNavigationOpportunityMandatInteraction(): Tracking.NavigationOpportunityMandatInteraction {
  return {
    event: "interaction",
    int_type: "navigation",
    int_description: "click",
    int_infos: "opportunity_seller_interest",
  };
}

export function buildOpportunityMandatInteraction(): Tracking.OpportunityMandatInteraction {
  return {
    event: "interaction",
    int_type: "mail",
    int_description: "opportunity_seller_interest",
    int_infos: "header",
  };
}

export function buildOpportunityMandatSubmitInteraction(
  email: string
): Tracking.OpportunityMandatSubmitInteraction {
  return {
    event: "interaction",
    int_type: "mail",
    int_description: "opportunity_seller_submit",
    int_infos: "header",
    email_lead: email,
    form_profile: "buyer",
  };
}

export function buildOpportunityPreneurLeadmailInteraction(
  infos: Tracking.OpportunityPreneurLeadmailInteractionIntInfo,
  email: string
): Tracking.OpportunityPreneurLeadmailInteraction {
  return {
    event: "interaction",
    int_type: "mail",
    int_description: "opportunity_buyer",
    int_infos: infos,
    email_lead: email,
    form_profile: "buyer",
  };
}

export function buildNavigationKeywordsTypedInteraction(): Tracking.NavigationKeywordsTypedInteraction {
  return {
    event: "interaction",
    int_type: "navigation",
    int_description: "click",
    int_infos: "search-keywords",
  };
}

export function buildOpportunityMandatStep1View(
  data: Tracking.TrackingView
): Tracking.OpportuniteMandatStep1 {
  return {
    event: "view",
    site: data.site,
    pagename: "opportunity-seller-step1",
    language: data.language,
    abtest: data.abtest,
    environment: data.environment,
    url_path: data.url_path,
    products: [],
  };
}

export function buildOpportunityMandatStep2View(
  data: Tracking.TrackingView
): Tracking.OpportuniteMandatStep2 {
  return {
    event: "view",
    site: data.site,
    pagename: "opportunity-seller-step2",
    language: data.language,
    abtest: data.abtest,
    environment: data.environment,
    url_path: data.url_path,
    products: [],
  };
}

export function buildOpportunityMandatStep3View(
  data: Tracking.TrackingView,
  estateType: TrackingEstateTypeEnum
): Tracking.OpportuniteMandatStep3 {
  return {
    event: "view",
    site: data.site,
    pagename: "opportunity-seller-step3",
    language: data.language,
    abtest: data.abtest,
    environment: data.environment,
    url_path: data.url_path,
    form_estate_type: estateType,
    products: [],
  };
}

export function buildOpportunityMandatStep4View(
  data: Tracking.TrackingView,
  estateType: TrackingEstateTypeEnum,
  codePostal: string
): Tracking.OpportuniteMandatStep4 {
  return {
    event: "view",
    site: data.site,
    pagename: "opportunity-seller-step4",
    language: data.language,
    abtest: data.abtest,
    environment: data.environment,
    url_path: data.url_path,
    form_estate_type: estateType,
    form_postalcode: codePostal,
    products: [],
  };
}

export function buildOpportunityMandatStep5View(
  data: Tracking.TrackingView,
  estateType: TrackingEstateTypeEnum,
  codePostal: string
): Tracking.OpportuniteMandatStep5 {
  return {
    event: "view",
    site: data.site,
    pagename: "opportunity-seller-step5",
    language: data.language,
    abtest: data.abtest,
    environment: data.environment,
    url_path: data.url_path,
    form_estate_type: estateType,
    form_postalcode: codePostal,
    products: [],
  };
}

export function buildNavigationAgrandissementCartoInteraction(
  isEnlarge: boolean
): Tracking.NavigationAgrandissementCartoInteraction {
  return {
    event: "interaction",
    int_type: "navigation",
    int_description: "click",
    int_infos: isEnlarge
      ? "search_result-enlarge_map"
      : "search_result-reduce_map",
  };
}

export function buildNavigationMapListCartoInteraction(
  isMap: boolean
): Tracking.NavigationMapListCartoInteraction {
  return {
    event: "interaction",
    int_type: "navigation",
    int_description: "click",
    int_infos: isMap
      ? "search_results-switch_map_button"
      : "search_results-switch_list_button",
  };
}

export function buildNavigationBarMobileInteraction(
  int_infos: Tracking.NavigationBarMobileIntInfo
): Tracking.NavigationBarMobileInteraction {
  return {
    event: "interaction",
    int_type: "navigation",
    int_description: "click",
    int_infos,
  };
}

export function buildDetailItemPricesInteraction(
  infos: Tracking.DetailItemPricesIntInfo
): Tracking.DetailItemPricesInteraction {
  return {
    event: "interaction",
    int_type: "navigation",
    int_description: "click",
    int_infos: infos,
  };
}

export function buildMapClassifiedImpressionInteraction(
  products: Tracking.TrackingProduct[]
): Tracking.MapClassifiedImpresstionInteraction {
  return {
    event: "interaction",
    int_type: "product",
    int_description: "impression",
    int_infos: "search_product",
    products: products,
  };
}

export function buildOpportunityServicielInteraction(
  infos: Tracking.OpportunityServicielIntInfo
): Tracking.OpportunityServicielInteraction {
  return {
    event: "interaction",
    int_type: "navigation",
    int_description: "click",
    int_infos: infos,
  };
}

export function buildHubLatestClassifedInteraction(
  products: Tracking.TrackingProduct[]
): Tracking.HubLatestClassifedInteraction {
  return {
    event: "interaction",
    int_type: "product",
    int_description: "click",
    int_infos: "lp_offices-similars_middle",
    products: products,
  };
}

export function buildBoostSuggestionInteraction(
  data: Tracking.TrackingView,
  sliderPage: number,
  products: Tracking.TrackingProduct[]
): Tracking.BoostSuggestionInteraction {
  return {
    event: "interaction",
    environment: data.environment,
    int_type: "product",
    int_description: "impression",
    int_infos:
      typeof sliderPage !== "number"
        ? "boost_extend"
        : `boost_extend_page${sliderPage}`,
    language: data.language,
    pagename: "search_results",
    products: products.map((p) => ({
      ...p,
      list_name: "boost_extend",
    })),
    referrer: data.referrer,
    site: data.site,
    url_path: data.url_path,
  };
}

export function buildBoostSuggestionClickInteraction(
  products: Tracking.TrackingProduct[]
): Tracking.BoostSuggestionClickInteraction {
  return {
    event: "interaction",
    int_type: "product",
    int_description: "click",
    int_infos: "boost_extend",
    products: products.map((p) => ({
      ...p,
      list_name: "boost_extend",
    })),
  };
}

export function buildCoworkingContactClickInteraction(
  infos: Tracking.CoworkingContactClickInteractionIntInfos,
  type: Tracking.CoworkingContactType,
  products: Tracking.TrackingProduct[],
): Tracking.CoworkingContactClickInteraction {
  return {
    event: 'interaction',
    int_type: type,
    int_description: type === 'mail' ? 'interest' : 'conversion',
    int_infos: infos,
    products: products
  }
}

export function buildCoworkingContactConversion(
  infos: Tracking.CoworkingContactClickInteractionIntInfos,
  email: string,
  products: Tracking.TrackingProduct[],
): Tracking.CoworkingMailConversionInteraction {
  return {
    event: 'interaction',
    int_type: "mail",
    int_description: "conversion",
    int_infos: infos,
    email_lead: email,
    products: products,
  }
}

export function buildCoworkingContactConfirmation(
  infos: Tracking.CoworkingContactClickInteractionIntInfos,
  email: string,
  products: Tracking.TrackingProduct[],
): Tracking.CoworkingMailConfirmationInteraction {
  return {
    event: 'interaction',
    int_type: "mail",
    int_description: "confirmation",
    int_infos: infos,
    email_lead: email,
    products: products,
  }
}

export function buildCoworkingPhonePostLeadConversion(
  infos: Tracking.CoworkingContactClickInteractionIntInfos,
  email: string,
  products: Tracking.TrackingProduct[],
): Tracking.CoworkingPhonePostLeadConversion {
  return {
    event: 'interaction',
    int_type: "phone",
    int_description: "conversion",
    int_infos: `${infos}-post-lead`,
    email_lead: email,
    products: products,
  }
}

export function buildNavigationOpportunityPreneurInteraction(
  infos: Tracking.NavigationOpportunityPreneurInteractionIntInfo
): Tracking.NavigationOpportunityPreneurInteraction {
  return {
    event: "interaction",
    int_type: "navigation",
    int_description: "click",
    int_infos: infos,
  };
}

export function buildOpportunityPreneurSubmitInteraction(
  email: string
): Tracking.OpportunityPreneurSubmitInteraction {
  return {
    event: "interaction",
    int_type: "mail",
    int_description: "opportunity_buyer_submit",
    int_infos: "header",
    email_lead: email,
    form_profile: "buyer",
  };
}

export const buildOpportunityPreneurView = (
  data: Tracking.TrackingView,
  step: number,
  extraData
): Tracking.TrackingView => {
  return {
    ...extraData,
    event: "view",
    site: data.site,
    pagename: `opportunity-buyer-step${step}`,
    language: data.language,
    abtest: data.abtest,
    environment: data.environment,
    url_path: data.url_path,
  };
};
