import React from "react";
import { createRoot } from "react-dom/client";
import Footer from "src/react/common/footer";
import Header from "src/react/common/header";
import SeoFooter from "./common/components/footer-and-seo-footer";
import { getUrlParameter } from "./utils/utils";

const header = document.getElementById("react-header");
if (header) {
  const root = createRoot(header)
  root.render(<Header />)
}

// Footer
const FooterReactTarget = document.getElementById("footer-react");
if (FooterReactTarget) {
  const root = createRoot(FooterReactTarget)
  root.render(<Footer />)
}

function manageOpeningPopin() {
  let modalParameter = getUrlParameter("modal");

  // Affichage de la modale opportunite mandat
  if (modalParameter == "mandat") {
    document.querySelector<HTMLElement>(".js-diffusez-annonce").click();
  }
  // Affichage de la modale opportunite preneur
  else if (modalParameter == "preneur") {
    document.querySelector<HTMLElement>(".js-confiez-recherche").click();
  }
}

function init() {
  window.addEventListener("load", function () {
    if ("IntersectionObserver" in window) {
      let SeoFooterElem = document.querySelector(".js-box-footer-target");
      if (SeoFooterElem) {
        let observer = new IntersectionObserver(function (entries) {
          entries.forEach(
            (entry) => {
              if (entry.isIntersecting) {
                SeoFooter.toggleLinksSeoFooter();
                observer.unobserve(entry.target);
              }
            },
            {
              rootMargin: "300px 0px 0px 0px",
            }
          );
        });
        observer.observe(SeoFooterElem);
      }
    }

    // hide links in SEO footer that we kept visible at the beginning, to keep the Google Robots happy
    let seoFooterTitles = document.getElementsByClassName(
      "js-footer-seo-collapsable"
    );
    if (seoFooterTitles) {
      for (let i = 0; i < seoFooterTitles.length; i++) {
        seoFooterTitles[i].classList.remove("open");
      }
    }

    // Gestion de l'ouverture de popin opportunité en cas de redirection
    manageOpeningPopin();

    const yearFooter = document.getElementById("year-footer");
    if (yearFooter) {
      let year = new Date().getFullYear();
      yearFooter.innerText = `${year}`;
    }
  });
}

init();
