import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { HeaderOpportunity } from "src/react/common/header/common/header_opportunity";
import {
  lockBodyWhileOverlay,
  unlockBodyWhileOverlay,
} from "src/react/tools/scroll";
import styled, { keyframes } from "styled-components";
import Keyframes from "styled-components/dist/models/Keyframes";
import { useViewportHeight } from "../../hooks/use-viewport-height";

type ModalDialogPropType = {
  $mobileHeight: string;
  $maxWidth: string;
  $appear: Keyframes;
};

type ModalProps = {
  onCloseModal: () => void;
  isVisible: boolean;
  maxWidth: string;
  children?: any;
  appear?: any;
  showHeader?: boolean;
};

export function Modal({
  onCloseModal,
  isVisible,
  maxWidth,
  children,
  appear,
  showHeader = false,
  ...props
}: ModalProps) {
  let content = children;
  const mobileHeight = useViewportHeight();

  useEffect(() => {
    if (isVisible) {
      lockBodyWhileOverlay();
    } else {
      unlockBodyWhileOverlay();
    }
  }, [isVisible]);

  return !isVisible ? (
    <></>
  ) : (
    createPortal(
      <ModalContainer data-test="modal-container">
        <ModalDialog
          data-test="modal-dialog"
          id="modal-dialog"
          {...props}
          $appear={appear}
          $mobileHeight={mobileHeight}
          $maxWidth={maxWidth}
          onClick={(e) => e.stopPropagation()}
        >
          {showHeader && <HeaderOpportunity onClose={onCloseModal}/> }
          {content}
        </ModalDialog>
      </ModalContainer>,
      document.body
    )
  );
}

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
 from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
`;

const ModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-tap-highlight-color: transparent;
  top: 0;
  font-size: 1.6rem;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
  transition: 0.3s opacity;
  vertical-align: middle;
  font-size: 14px;
  z-index: 99990;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  animation-name: ${appear};
  animation-duration: 300ms;
`;

const ModalDialog = styled.div<ModalDialogPropType>`
  @media screen and (min-width: 768px) {
    width: 100%;
    height: fit-content;
    min-height: 0;
    margin: 30px auto;
    border-radius: 16px;
  }

  max-width: ${({ $maxWidth: maxWidth }) => maxWidth};
  height: 100%;
  background-color: #fff;
  position: relative;
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  cursor: default;
  text-align: left;
  transition: 0.3s transform;
  vertical-align: middle;
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.24);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: ${({ $appear: appear }) => appear || slideIn};
  animation-duration: 0.5s;

  ::-webkit-scrollbar {
    -webkit-appearance: none !important;
    width: 0px !important;
    background-color: transparent !important;
  }
`;
