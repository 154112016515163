export function scrollOnTop() {
	window?.scrollTo(0, 0);
}
export function scrollToElement(id: string) {
	const container = document.getElementById(id) as HTMLElement;
	container.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
}
export function lockBodyWhileOverlay() {
	document.body.style.overflow = 'hidden';
}

export function unlockBodyWhileOverlay() {
	if (document.body.style.overflow == 'hidden') {
		document.body.style.overflow = 'auto';
	}
}