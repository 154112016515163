export enum TrackingTypeBienEnum {
  PLOT = 4,
  SHOP = 6,
  STORAGE = 7,
  COMMERCIAL_SPACE = 7.1,
  OFFICE = 8,
  OFFICE_COMMERCIAL_LEASE = 8.1,
  OFFICE_SHORT_LEASE = 8.2,
  OFFICE_SERVICED = 8.3,
  COWORKING = 17,
  COWORKING_SPACE = 18,
  COWORKING_WORKSTATION = 18.2,
  COWORKING_PRIVATE_WORKSTATION = 18.5,
  COWORKING_PRIVATE_OFFICE = 18.3,
  BUSINESS_SOLD = 31,
}

export enum TrackingDistributionSubtype {
  LOCATION = 1,
  VENTE = 2,
  VENTE_FONDS_DE_COMMERCE = 10,
  CESSION_DE_BAIL = 11,
  PAS_DE_PORTES = 22,
}

export enum TrackingItemListName {
  CLASSIFIED_DETAIL = "classified_detail",
  CLASSIFIED_DETAIL_SIMILARS_BOTTOM = "classified_detail_similars_bottom",
  CLASSIFIED_DETAIL_POST_CONTACT = "classified_detail_post_contact",
  CLASSIFIED_SEARCH_RESULTS = "classified_search_results",
  CLASSIFIED_SEARCH_RESULTS_MAP = "classified_search_results_map",
  LOCAL_EXPERT = "local_expert",
  MASTERVIGNETTE = "mastervignette",
  SPONSORED = "sponsored",
  PRICE_MAP = "price_map",
  SELLER_CONFIRMATION = "seller_confirmation",
  SELLER_START = "seller_start",
  SELLER_SELECTED = "seller_selected",
  SOLD_PRODUCTS = "sold_products",
  HOMEPAGE_LAST_SEEN = "homepage_last_seen",
  HOMEPAGE_LAST_SEARCH = "homepage_last_search",
  HOMEPAGE_LAST_CLASSIFIED = "homepage_last_classified",
  RECOMMENDATIONS = "recommendations",
  ACCOUNT_FAVORITES = "account_favorites",
  ACCOUNT_LAST_SEEN = "account_last_seen",
  ACCOUNT_PUBLICATION = "account_publication",
  BOOST_EXTEND = "boost_extend",
  AGENCY_DIRECTORY = "agency_directory",
}

export enum TrackingZoneEnum {
  HEADER = "header",
  TOP = "top",
  MIDDLE = "middle",
  BOTTOM = "bottom",
  DETAIL_AGENCY_BLOCK = "detail_agency_block",
  DETAIL_HEADER = "detail_header",
  FOOTER = "footer",
  CONTACT_CARD = "contact_card",
  STICKY = "sticky",
  FIXED_MOBILE_CTA_BUTTONS = "fixed-mobile-cta-buttons",
  MAP = "map",
}

export enum TrackingListNameEnum {
    SEARCH_RESULTS_CLIENT = "search_results_client",
    CLASSIFIED_DETAIL = "classified_detail",
    AGENCY_DIRECTORY = "agency_directory",
}
  

export enum TrackingBookmarksInitiativeEnum {
  WL_HP = 'wl_hp',
  WL_PP = 'wl_pp',
  WL_SERP = 'wl_serp',
}

export enum TrackingCategoryEnum {
  HOME = 'homepage',
  CLASSIFIED = 'classified',
  HUB = 'hub',
  ALERT = 'alert',
  FIND_PRO = 'find_pro',
  FOOTER = 'footer',
  SUBMIT = 'submit',
  WISHLIST = 'wishlist',
  ERROR = 'error',
  OTHER = 'other',
}
