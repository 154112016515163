import { isMobile } from "./utils"

/**
 * Ad Manager for googleTag
 */
export default class AdsManager {
    constructor(googletag) {
        this.slots = [];
        this.idList = [];
        this.gTag = googletag;
    }
    /**
     * Initialize the different ad slots present in the page
     * @param {string} pageId - ex : /1234567/SiteName/List_Page
     */
    init(pageId) {
        var adList = document.querySelectorAll("div[data-ad-id]")
        var idList = [];
        var slots = [];
        var gTag = this.gTag;
        //ad sorting to define ads display priority
        if (adList && adList.length > 0) {
            var sortedArray = Array.from(adList).sort(function (a, b) {
                a = parseInt(a.attributes[1].nodeValue);
                b = parseInt(b.attributes[1].nodeValue);
                if (a < b) {
                    return -1;
                }

                if (a > b) {
                    return 1;
                }

                return 0;
            });
            gTag.cmd.push(function () {
                //slots initialization          
                sortedArray.forEach(function (elem) {
                    var id = elem.getAttribute("data-ad-id");
                    idList.push(id);
                    var defaultSize = JSON.parse(elem.getAttribute("data-ad-default-size"));
                    var target = JSON.parse(elem.getAttribute("data-ad-target"));
                    var unitPath = elem.getAttribute("data-ad-unit-path");
                    if (defaultSize && id && target && unitPath) {
                        var sizeByBrowser = JSON.parse(elem.getAttribute("data-ad-size-by-browser"));
                        var slot = gTag.defineSlot(unitPath, defaultSize, id);
                        slot.setTargeting(target.key, target.values);

                        if (sizeByBrowser) {
                            var slotMapping = gTag.sizeMapping();
                            if (slotMapping) {
                                sizeByBrowser.forEach(function (size) {
                                    slotMapping = slotMapping.addSize(size.browserResolution, size.sizes);
                                });
                                slotMapping = slotMapping.build();
                                slot = slot.defineSizeMapping(slotMapping);
                            }
                        }

                        slot.addService(gTag.pubads());
                        slots.push({
                            slotId: id,
                            slot: slot
                        });
                    }
                });

                //campaign targeting
                setTargeting();
                gTag.pubads().disableInitialLoad();
                gTag.pubads().enableSingleRequest();
                gTag.pubads().collapseEmptyDivs();
                gTag.enableServices();
                gTag.pubads().refresh([slots[0].slot]);
            });

            this.slots = slots;
            this.idList = idList;
        }

        return this;
    }

    /**
     * Add callback to specific googleTag event and for a list of specific slots
     * @param {Array<Object>} eventArray - Array of events
     */
    addEvent(eventArray) {
        var slots = this.slots;
        function triggerEvent(eventName, event) {
            var event = new CustomEvent(eventName, {
                detail: {
                    size: event.size,
                    isEmpty: event.isEmpty
                }
            });
            document.dispatchEvent(event);
        }
        eventArray.forEach(element => {
            this.gTag.pubads().addEventListener(element.eventName, function (event) {
                element.events.forEach(elem => {
                    var elemSlot = slots.find(slot => {
                        return slot.slotId === elem.slotId;
                    });
                    if (elemSlot && event.slot === elemSlot.slot) {
                        triggerEvent(elem.eventName, event);
                    }
                });
            });
        }, this);

        return this;
    }

    /**
     * refresh ads slot using slot ids, if no slot ids are passed, refresh all the slots in the page 
     * @param {Array<Object>} slotIds - Array of slot ids
     */
    refresh(slotIds) {
        if (slotIds && slotIds.length > 0) {
            var slotToRefresh = [];
            slotIds.forEach(sl => {
                var slot = this.slots.find(slt => slt.slotId == sl);
                if (slot) {
                    this.gTag.pubads().refresh([slot.slot]);
                }
            })
        } else {
            this.gTag.pubads().refresh();
        }

        return this;
    }

    /**
     * Enable the google Ads Service and display the ad in order
     */
    fetch() {
        var gTag = this.gTag;
        var idList = this.idList;
        var slots = this.slots;
        gTag.cmd.push(function () {
            gTag.enableServices();
            if (idList && idList.length > 0) {
                idList.forEach(function (id) {
                    gTag.display(id);
                    var slot = slots.find(slt => slt.slotId == id);
                    if (slot) {
                        gTag.pubads().refresh([slot.slot]);
                    }
                })
            };
        });

        return this;
    }
}

export function setTargeting() {
    var campaignTarget = document.querySelector("#ad-target");
    if (campaignTarget) {
        var targetObj = JSON.parse(campaignTarget.getAttribute("data-ad-search-target"));
        if (targetObj && typeof googletag !== 'undefined' && googletag && googletag.pubads) {
            var targetEntries = Object.entries(targetObj);
            var targetCall = "googletag.pubads()";
            targetEntries.forEach(([key, value]) => {
                targetCall += ".setTargeting('" + key + "','" + value + "')";
            });
            eval(targetCall);
        }
    }
}

export function updateAds() {
    if (typeof googletag !== 'undefined' && googletag && googletag.pubads) {
        googletag.pubads().refresh();
    }
}
