import { BusinessSubType, BusinessType } from "src/types/classifieds"

export const TrackingBusinessTypeGA4: Record<BusinessType, string> = {
  [BusinessType.None]: 'NONE',
  [BusinessType.FOOD]: '1',
  [BusinessType.TRANSPORT]: '2',
  [BusinessType.GASTRO_HOTEL]: '3',
  [BusinessType.LOCAL_SHOP]: '4',
  [BusinessType.WELLNESS_HEALTHCARE]: '5',
  [BusinessType.HOUSE_EQUIPMENT]: '6',
  [BusinessType.COMPUTER_TELECOM]: '7',
  [BusinessType.LEISURE]: '8',
  [BusinessType.FASHION]: '9',
  [BusinessType.HEALTH]: '10',
  [BusinessType.LOCAL]: '11',
}

export const TrackingBusinessSubTypeGA4 : Record<BusinessSubType, string> = {
  [BusinessSubType.None]: 'NONE',
  [BusinessSubType.BUTCHER_SHOP]: '1.1',
  [BusinessSubType.BAKERY]: '1.2',
  [BusinessSubType.CHOCOLATES_CANDIES]: '1.3',
  [BusinessSubType.GROCERY]: '1.4',
  [BusinessSubType.CHEESE_DAIRY]: '1.5',
  [BusinessSubType.FISH_STORE]: '1.6',
  [BusinessSubType.GREENGROCERY]: '1.7',
  [BusinessSubType.CATERING]: '1.8',
  [BusinessSubType.WINE]: '1.9',
  [BusinessSubType.MINI_MARKET]: '1.10',
  [BusinessSubType.SUPERMARKET]: '1.11',
  [BusinessSubType.DRIVING_SCHOOL]: '2.1',
  [BusinessSubType.CAR_MOTORCYCLE_DEALER]: '2.2',
  [BusinessSubType.GARAGE_SERVICE_STATION]: '2.3',
  [BusinessSubType.CAFE_BAR_PUB]: '3.1',
  [BusinessSubType.CAMPING]: '3.2',
  [BusinessSubType.CREPERIE]: '3.3',
  [BusinessSubType.ICECREAM]: '3.4',
  [BusinessSubType.HOTEL]: '3.5',
  [BusinessSubType.PIZZERIA]: '3.6',
  [BusinessSubType.RESTAURANT]: '3.7',
  [BusinessSubType.FASTFOOD]: '3.8',
  [BusinessSubType.TEAROOM]: '3.9',
  [BusinessSubType.GIFT_SHOP]: '4.1',
  [BusinessSubType.FLORIST]: '4.2',
  [BusinessSubType.HABERDASHERY]: '4.3',
  [BusinessSubType.KIOSK]: '4.4',
  [BusinessSubType.HAIRDRESSING]: '5.1',
  [BusinessSubType.BEAUTY_STORE]: '5.2',
  [BusinessSubType.PERFUMERY]: '5.3',
  [BusinessSubType.FURNISHING]: '6.1',
  [BusinessSubType.HOUSEHOLD_APPLIANCES]: '6.2',
  [BusinessSubType.COMPUTER_SHOP_OFFICE_SUPPLY]: '7.1',
  [BusinessSubType.PHONES]: '7.2',
  [BusinessSubType.TRAVEL_AGENCY]: '8.1',
  [BusinessSubType.ARTS_MUSIC]: '8.2',
  [BusinessSubType.SPORTS_CLUB]: '8.3',
  [BusinessSubType.CLUB_DISCOTHEK]: '8.4',
  [BusinessSubType.ARTS_GALLERY]: '8.5',
  [BusinessSubType.BOOKS]: '8.7',
  [BusinessSubType.TOYS]: '8.8',
  [BusinessSubType.JEWELLERY]: '9.1',
  [BusinessSubType.SHOES]: '9.2',
  [BusinessSubType.LEATHER]: '9.3',
  [BusinessSubType.CLOTHES]: '9.4',
  [BusinessSubType.TAXI_AMBULANCE]: '10.1',
  [BusinessSubType.DENTAL]: '10.2',
  [BusinessSubType.PHYSIOTHERAPIST]: '10.3',
  [BusinessSubType.RETIREMENT_HOME]: '10.4',
  [BusinessSubType.OPTICAL]: '10.5',
  [BusinessSubType.PHARMACY]: '10.6',
  [BusinessSubType.REAL_ESTATE]: '11.1',
  [BusinessSubType.PETS]: '11.2',
  [BusinessSubType.DIY]: '11.3',
  [BusinessSubType.SHOE_REPAIR]: '11.4',
  [BusinessSubType.RELOCATION]: '11.5',
  [BusinessSubType.ELECTRICITY]: '11.6',
  [BusinessSubType.GARDENER]: '11.7',
  [BusinessSubType.LAUNDROMAT]: '11.8',
  [BusinessSubType.CARPENTRY]: '11.9',
  [BusinessSubType.PHOTO]: '11.10',
  [BusinessSubType.PLUMBING_HEATING]: '11.11',
  [BusinessSubType.LAUNDRY]: '11.12',
  [BusinessSubType.LOCKSMITH]: '11.13',
  [BusinessSubType.GLASSMAKING]: '11.14',
}
