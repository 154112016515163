import React, { ComponentType, lazy, Suspense } from "react";
import { Modal } from "src/react/common/modal";

import { usePreneurStepper } from "./context";
import { TrackingZoneEnum } from "src/js/tracking/ga4/types/enums";

const PreneurOpportunityContent = lazy(() => import("./components/content"));

const LazyPreneurOpportunityContent = (
  props: Parameters<typeof PreneurOpportunityContent>[0]
) => (
  <Suspense fallback="">
    <PreneurOpportunityContent {...props} />
  </Suspense>
);

import {
  PreneurOpportunityContainer,
  PreneurOpportunityButton,
  HiddenButton,
} from "./styles";

export default function (): ComponentType<{}> {
  return function PreneurView() {
    const { onOpenModal, isModalOpen } = usePreneurStepper();
    return (
      <PreneurOpportunityContainer>
        <PreneurOpportunityButton
          data-test="opp-preneur-button"
          size="small"
          kind="secondary"
          variant="normal"
          tag="button"
          css=""
          id="opp-preneur-button"
          onClick={() => onOpenModal(TrackingZoneEnum.HEADER)}
        >
          {`Confiez votre recherche`}
        </PreneurOpportunityButton>

        <HiddenButton
          type="button"
          data-test="opp-preneur-button-hidden"
          id="opp-preneur-button-hidden"
          onClick={() => onOpenModal(TrackingZoneEnum.MIDDLE)}
        />

        {/* MODAL OPPORTUNITY TAKER */}
        <Modal
          onCloseModal={onOpenModal}
          isVisible={isModalOpen}
          maxWidth="95.6rem"
          showHeader={true}
        >
          <LazyPreneurOpportunityContent onClose={onOpenModal} />
        </Modal>
      </PreneurOpportunityContainer>
    )
  };
}
