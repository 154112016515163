import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PropsOpenResponsive } from './types';

const Wrapper = styled.div`
  width: 100%;
  &:not(:last-of-type){
    margin-bottom: 2.4rem;
  }
  @media screen and (max-width: 991px) {
    cursor: pointer;
    &:first-of-type {
      margin-top: 2.4rem;
    }
  }
`;

const Subtitle = styled.div`
  width: 100%;
  font-size: 1.6rem;
  font-weight: 700;
  border-bottom: 1px solid #7d7d7d;
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem;
  @media screen and (max-width: 991px) {
    position: relative;
  }
`;

const Arrow = styled.div<PropsOpenResponsive>`
  @media screen and (max-width: 991px) {
    position: absolute;
    content: '';
    width: 2rem;
    height: 2rem;
    background: url(/Content/App/dist/img/arrow-thin-grey-right.svg) right
      center no-repeat;
    right: 0;
    bottom: 0.8rem;
    transform: rotate(90deg);
    filter: brightness(3);
    transition: transform linear 0.3s;
    transform: ${props => props.$open ? 'rotate(-90deg)' : 'rotate(90deg)'};
  }
`;
const Nav = styled.nav<PropsOpenResponsive>`
  display: block;
  @media screen and (max-width: 991px) {
    display: ${props => (props.$open ? 'block' : 'none')};
  }
`;

const Ul = styled.ul`
  @media screen and (min-width: 992px) {
    column-count: 3;
  }
  @media screen and (max-width: 991px) {
    column-count: 1;
  }
  margin: 0;
  padding: 0;
  list-style: none;
`

const Li = styled.li`
  margin-bottom: 0.8rem;
  cursor: pointer;
`

const A = styled.a`
  color: white;
  text-decoration: none;
  background-color: transparent;
  &:hover {
    color: white;
  }  
  &:focus {
    color: white;
  }
`

const InfoPratique = ({ model }: { model: footerreact.FooterModel }) => {
  const [subtitleOpen, setsubtitleOpen] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const handleClick = function () {
    setDisplayModal(true);
  };

  const handleContactClick = function () {
    document.dispatchEvent(new CustomEvent('open-service-client-modal'))
  }

  useEffect(() => {
    if (displayModal) {
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "unset";
  }, [displayModal]);
  const data = model.links || [];
  return (
    <Wrapper>
      <Subtitle
        onClick={() => {
          setsubtitleOpen(!subtitleOpen);
        }}
      >
        {model.subtitle}
        <Arrow
          $open={subtitleOpen}
          onClick={() => {
            setsubtitleOpen(!subtitleOpen);
          }}
        />
      </Subtitle>
      <Nav $open={subtitleOpen}>
        <Ul>
          {data.map(function (info) {
            return info.id === '2' && info.value.indexOf('Nous contacter') >= 0 ? (
              <Li
                key={info.id}
                onClick={() => { handleContactClick() }}
                style={{ visibility: (document.body.classList.contains('homepage') || document.body.classList.contains('quiSommesNous')) ? 'visible' : 'hidden' }}
              >
                {info.value}
              </Li>
            ) :
              info.id === '3' && info.value.indexOf('Paramétrer mes cookies') >= 0 ? (
                <Li
                  key={info.id}
                  onClick={() => {
                    window.__AVIV_CSNT__?.showBanner()
                  }}
                >
                  {info.value}
                </Li>
              ) :
                info.id === '4' && info.value.indexOf('Fonctionnement du site') >= 0 ? (
                  <Li
                    key={info.id}
                    onClick={() => { handleClick() }}
                  >
                    {info.value}
                  </Li>
                ) :
                  (
                    <Li key={info.id}>
                      <A href={info.href}>{info.value} </A>
                    </Li>
                  );
          })}
        </Ul>
      </Nav>
    </Wrapper>
  );
};
export default InfoPratique;
