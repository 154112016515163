import thor from "@sl/thor";
import { readContact } from "src/react/services/storage-service";
import { cryptEmail } from "./tracking-tool";
import { ThorViewMetas, ThorViewsGA4Interface } from "@sl/react-thor";
type PageName = 'detail' | 'search_results';

export let TRACKING_COMMON_PAGE_DATA: ThorViewsGA4Interface = {
    av_url_path: typeof window !== 'undefined' ? window.document.location.pathname : '',
    av_environment: document.location.host === 'www.seloger-bureaux-commerces.com'
        ? 'prod'
        : 'preprod',
    av_language: 'fr',
    av_platform: 'web',
    av_site: 'SLBC',
    av_items: []
}

export function sendViewCommon(pageName: PageName) {
    const emailLead = cryptEmail(readContact()?.email);

    thor.pushEvent('page_view', {
        page_name: pageName,
        user: { email_lead: emailLead }
    });
}

export function sendGA4PageView(value: Partial<ThorViewsGA4Interface>) {
    TRACKING_COMMON_PAGE_DATA = {
        ...TRACKING_COMMON_PAGE_DATA,
        ...value
    }

    thor.pushEvent('page_view', {
        ...TRACKING_COMMON_PAGE_DATA,
    });
}

export const defaultGa4MetasParams: ThorViewMetas = {
  av_url_path:
    typeof window !== "undefined" ? window.document.location.pathname : "",
  av_environment:
    document.location.host === "www.seloger-bureaux-commerces.com"
      ? "prod"
      : "preprod",
  av_language: "fr",
  av_platform: "web",
  av_site: "SLBC",
}
