import { MandatModel } from "src/types/container/mandat";
import { PreneurModel } from "src/types/container/preneur";

const OppMandatKey = "seller-opportunite";
const OppPreneurKey = "seeker-opportunite";
const ContactFormKey = "contact-form";
const AlertFormKey = "alert-form";


function tryRead<T = any>(key: string) {
  try {
    return JSON.parse(localStorage.getItem(key)) as T;
  } catch (e) {
    return null;
  }
}

function tryWrite<T = any>(key: string, value: T) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {}
}

export function readOppMandat(): MandatModel {
  const value = tryRead(OppMandatKey);
  const contactInfo = readContact();

  return {
    typeBien: value?.typeBien,
    transaction: value?.transaction,
    cessionBail: value?.cessionBail,
    secteurActivite: value?.secteurActivite,
    activite: value?.activite,
    localite: value?.localite,
    surface: value?.surface,
    prix: value?.prix,
    description: value?.description,
    nom: value?.nom || contactInfo.nom,
    email: value?.email || contactInfo?.email,
    tel: value?.tel || contactInfo.tel,
    currentStep: value?.currentStep,
    loading: false,
    isFailed: false,
    errors: {
      typeBien: null,
      transaction: null,
      cessionBail: null,
      secteurActivite: null,
      localite: null,
      surface: null,
      prix: null,
      nom: null,
      email: null,
      tel: null,
    },
  };
}

export function writeOppMandat(value: MandatModel): void {
  tryWrite(OppMandatKey, {
    typeBien: value?.typeBien,
    transaction: value?.transaction,
    cessionBail: value?.cessionBail,
    secteurActivite: value?.secteurActivite,
    activite: value?.activite,
    localite: value?.localite,
    surface: value?.surface,
    prix: value?.prix,
    description: value?.description,
    nom: value?.nom,
    email: value?.email,
    tel: value?.tel,
    currentStep: value?.currentStep,
    loading: false,
    isFailed: false,
    errors: {
      typeBien: null,
      transaction: null,
      cessionBail: null,
      secteurActivite: null,
      localite: null,
      surface: null,
      prix: null,
      nom: null,
      email: null,
      tel: null,
    },
  });
}

export function readOppPreneur(): PreneurModel {
  const value = tryRead(OppPreneurKey);
  const contactInfo = readContact();

  return {
    // Step 1
    idTypeTransaction: value?.idTypeTransaction,
    codeTypeBien: value?.codeTypeBien,
    localite: value?.localite,
    surface: value?.surface,
    // Step 2
    secteurActivite: value?.secteurActivite,
    activite: value?.activite,
    effectif: value?.effectif,
    // Step 3
    dateInstallation: value?.dateInstallation,
    message: value?.message,
    // Step 4
    societe: value?.societe,
    nom: value?.nom || contactInfo?.nom,
    email: value?.email || contactInfo?.email,
    telephone: value?.telephone || contactInfo?.tel,
    civilite: value?.civilite,

    isFailed: false,
    errors: {},
  };
}

export function writeOppPreneur(value: PreneurModel): void {
  tryWrite(OppPreneurKey, {
    // Step 1
    idTypeTransaction: value?.idTypeTransaction,
    codeTypeBien: value?.codeTypeBien,
    localite: value?.localite,
    surface: value?.surface,
    // Step 2
    secteurActivite: value?.secteurActivite,
    activite: value?.activite,
    effectif: value?.effectif,
    // Step 3
    dateInstallation: value?.dateInstallation,
    message: value?.message,
    // Step 4
    societe: value?.societe,
    nom: value?.nom,
    email: value?.email,
    telephone: value?.telephone,
    civilite: value?.civilite,

    isFailed: false,
    errors: {
      dateInstallation: null,
      societe: null,
      nom: null,
      email: null,
      telephone: null,
      civilite: null,
    },
  });
}

export function readContact(): Contact.ContactInformation {
  const value = tryRead(ContactFormKey);
  const aliContactInfo = readAliModel();
  return {
    nom: value?.nom,
    tel: value?.tel,
    email: value?.email || aliContactInfo?.email,
  };
}

export function writeContact(value: Contact.ContactInformation): void {
  tryWrite(ContactFormKey, {
    nom: value?.nom,
    tel: value?.tel,
    email: value?.email,
  });
}

export function readAliModel(): ali.Model {
  const value = tryRead(AlertFormKey);

  return {
    email: value?.email,
    newsletter: value?.newsletter,
    partenaire: value?.partenaire,
  };
}
