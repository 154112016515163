import React, { useState } from 'react';
import styled from 'styled-components';
import { scroll } from "src/js/utils/scroll";
import { Button } from '@ui-library/core/Button';
import OppMandatModal from './modal';
import { trackNavigationOpportunityMandatInteraction } from 'src/js/tracking/ga3/tracking-wrapper';
import { sendEventSellerOpportunity } from "../../../../../js/tracking/ga4/events/opportunity/seller"
import { TrackingZoneEnum } from 'src/js/tracking/ga4/types/enums';

const HeaderButton = styled(Button).attrs(() => ({
	className: 'link-action-header opportunite js-diffusez-annonce',
	"data-test": 'openLaasMandatModal',
}))`   
    height: auto;
    min-height: 32px;
`;

const ButtonContainer = styled.div`
	@media screen and (min-width: 992px) {
		margin-left: 0.8rem;
	}
`;

function closeMobileMenu() {
	const mobileMenu = document.querySelector('header');
	let mobileMenuOpen = mobileMenu.querySelector('.header-icon.closing');
	if (mobileMenuOpen) {
		mobileMenuOpen.classList.remove('closing');
		let subMenuOpen = mobileMenu.querySelector('nav.is-visible');
		if (subMenuOpen) {
			subMenuOpen.classList.remove('is-visible');
		}
		document.querySelector('#black-overlay.overlay--show').classList.remove('overlay--show');
	}
}

export default function Mandat() {
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = function () {
        setIsOpen(false);
    };
    const handleCLick = function () {
        trackNavigationOpportunityMandatInteraction();
        sendEventSellerOpportunity(TrackingZoneEnum.HEADER);
        closeMobileMenu();
        scroll.enableScroll(); // fix bug Safari
        setIsOpen(true);
    };

    return (
        <ButtonContainer>
            <HeaderButton 
                data-test="opp-preneur-button link-button"
                size="small"
                kind="secondary"
                variant="normal"
                tag="button"
                onClick={() => handleCLick()}>
                Confiez votre bien</HeaderButton>
            <OppMandatModal opened={isOpen} onClose={handleClose} />
        </ButtonContainer>
    )
}
